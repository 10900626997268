import { ChevronDownIcon, ChevronRightIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { HStack, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import {
    CellContext,
    Column,
    createColumnHelper,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortDirection,
    useReactTable,
} from '@tanstack/react-table';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DashboardRowData } from '../types/api-types';
import {
    COMMON_LOCALES,
    CROSSWARP_STATUS_KEY,
    ERROR_LOCALES,
    GARMENT_STATUS_KEY,
    LAYOUT_LOCALES,
    PREFILTERS,
    WARP_STATUS_KEY,
} from '../utils/constants';
import CellValue from './CellValue';

const DEFAULT_COMPARE_VISIBILITY = {
    [`state.garment.status.compare.${PREFILTERS.TODO}`]: false,
    [`state.garment.status.compare.${PREFILTERS.TOQUALITY}`]: false,
    [`state.garment.status.compare.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.garment.status.compare.${PREFILTERS.READY}`]: false,
    [`state.garment.status.compare.${PREFILTERS.READY}.percentage`]: false,
    [`state.warp.status.compare.${PREFILTERS.TODO}`]: false,
    [`state.warp.status.compare.${PREFILTERS.MISSINGALLOWED}`]: false,
    [`state.warp.status.compare.${PREFILTERS.TOQUALITY}`]: false,
    [`state.warp.status.compare.${PREFILTERS.TOINTQC}`]: false,
    [`state.warp.status.compare.${PREFILTERS.TOEXTQC}`]: false,
    [`state.warp.status.compare.${PREFILTERS.TOHDQC}`]: false,
    [`state.warp.status.compare.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.warp.status.compare.${PREFILTERS.READY}`]: false,
    [`state.warp.status.compare.${PREFILTERS.READY}.percentage`]: false,
    [`state.crosswarp.status.compare.${PREFILTERS.MISSINGSIZING}`]: false,
    [`state.crosswarp.status.compare.${PREFILTERS.MISSINGOVERRIDE}`]: false,
    [`state.crosswarp.status.compare.${PREFILTERS.MISSINGALLOWED}`]: false,
    [`state.crosswarp.status.compare.${PREFILTERS.TOQUALITY}`]: false,
    [`state.crosswarp.status.compare.${PREFILTERS.TOHDQC}`]: false,
    [`state.crosswarp.status.compare.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.crosswarp.status.compare.${PREFILTERS.READY}`]: false,
    [`state.crosswarp.status.compare.${PREFILTERS.READY}.percentage`]: false,
    'input.garment.compare': false,
    'input.model.compare': false,
    'state.crosswarp.compare.target': false,
    'state.finished.compare.READY': false,
    'state.finished.compare.READY.percentage': false,
    'state.finished.compare.target': false,
    'state.finished.pose.compare.BACK': false,
    'state.finished.pose.compare.FRONT': false,
    'state.warp.compare.target': false,
};

const DEFAULT_VISIBILITY = {
    [`state.garment.status.${PREFILTERS.TODO}`]: false,
    [`state.garment.status.${PREFILTERS.TOQUALITY}`]: false,
    [`state.garment.status.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.garment.status.${PREFILTERS.READY}`]: false,
    [`state.garment.status.${PREFILTERS.READY}.percentage`]: true,
    [`state.crosswarp.status.${PREFILTERS.MISSINGSIZING}`]: false,
    [`state.crosswarp.status.${PREFILTERS.MISSINGOVERRIDE}`]: false,
    [`state.crosswarp.status.${PREFILTERS.MISSINGALLOWED}`]: false,
    [`state.crosswarp.status.${PREFILTERS.TOQUALITY}`]: false,
    [`state.crosswarp.status.${PREFILTERS.TOHDQC}`]: false,
    [`state.crosswarp.status.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.crosswarp.status.${PREFILTERS.READY}`]: false,
    [`state.crosswarp.status.${PREFILTERS.READY}.percentage`]: true,
    'state.crosswarp.target': false,
    'state.finished.READY': false,
    'state.finished.READY.percentage': true,
    'state.finished.pose.BACK': false,
    'state.finished.pose.FRONT': false,
    'state.finished.target': false,
    [`state.warp.status.${PREFILTERS.TODO}`]: false,
    [`state.warp.status.${PREFILTERS.MISSINGALLOWED}`]: false,
    [`state.warp.status.${PREFILTERS.TOQUALITY}`]: false,
    [`state.warp.status.${PREFILTERS.TOINTQC}`]: false,
    [`state.warp.status.${PREFILTERS.TOEXTQC}`]: false,
    [`state.warp.status.${PREFILTERS.TOHDQC}`]: false,
    [`state.warp.status.${PREFILTERS.HASFEEDBACK}`]: false,
    [`state.warp.status.${PREFILTERS.READY}`]: false,
    [`state.warp.status.${PREFILTERS.READY}.percentage`]: true,
    'state.warp.target': false,
};

// ---- Pinned columns array (NEED TO BE ORDERED AS IN THE TABLE) ----
const PINNED_COLUMN_NAMES = ['client.name', 'experience.name', 'input.model', 'input.model.compare', 'input.garment', 'input.garment.compare'];

interface DashboardTableProps {
    dashboardData?: DashboardRowData[] | null,
    search?: string,
    isDashboardLoading?: boolean,
}

const DashboardTable = React.forwardRef<{ exportHtml:(toDate?: string, fromDate?: string) => void }, DashboardTableProps>((
    props, ref,
) => {
    const { t } = useTranslation([LAYOUT_LOCALES]);
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const { dashboardData, search, isDashboardLoading } = props;

    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({ ...DEFAULT_VISIBILITY, ...DEFAULT_COMPARE_VISIBILITY });
    const [pinnedColOffsets, setPinnedColOffsets] = useState<number[]>([]);

    const tableRef = useRef<HTMLTableElement>(null);

    const isCompareMode = useMemo(() => !!dashboardData?.find((data) => !!data.compareState), [dashboardData]);

    const getSortIcon = (sortName: SortDirection | false) => {
        if (!sortName) {
            return null;
        }

        switch (sortName) {
            case 'asc':
                return <TriangleUpIcon />;
            case 'desc':
                return <TriangleDownIcon />;
            default:
                return null;
        }
    };

    const getDefaultVisibility = () => {
        const newVisibility = { ...DEFAULT_VISIBILITY };

        Object.keys(DEFAULT_COMPARE_VISIBILITY).forEach((compareKey) => {
            // ---- If we have the compare mode we activate the base columns ----
            if (isCompareMode && [
                'input.model.compare',
                'input.garment.compare',
                `state.garment.status.compare.${PREFILTERS.READY}.percentage`,
                `state.warp.status.compare.${PREFILTERS.READY}.percentage`,
                `state.crosswarp.status.compare.${PREFILTERS.READY}.percentage`,
                'state.finished.compare.READY.percentage',
            ].includes(compareKey)) {
                newVisibility[compareKey] = true;

                return;
            }

            newVisibility[compareKey] = false;
        });

        return newVisibility;
    };

    const getComparePrefix = (cellValue: number) => {
        if (cellValue > 0) {
            return '+';
        }

        return '';
    };

    const getCompareCellStyleFromValue = (cellValue: number, withColor?: boolean) => {
        let colorValue = 'lightgray';

        if (cellValue > 0) {
            colorValue = '#44D14A';
        }

        if (cellValue < 0) {
            colorValue = 'red';
        }

        return { color: !withColor && cellValue !== 0 ? undefined : colorValue, justifyContent: 'flex-end', paddingLeft: 0 };
    };

    const handleTableHeaderClick = (headerColumn?: Column<DashboardRowData>) => {
        if (!headerColumn || ['client.name', 'experience.name', 'input'].includes(headerColumn.id)) {
            return;
        }

        if (headerColumn.depth === 0) {
            // ---- Need to clone the state to modify it ----
            const result = JSON.parse(JSON.stringify(visibility));

            // ---- For each subcolumn we hide those that are not ready ----
            headerColumn.columns.forEach((column) => {
                // ---- We don't touch the compare columns if the mode is not activated ----
                if (!isCompareMode && column.id.match('compare')) {
                    return;
                }

                result[column.id] = !result[column.id];
            });
            setVisibility(result);

            return;
        }

        headerColumn.toggleSorting();
    };

    const getStyleBorderStyle = (column: Column<DashboardRowData>): CSSProperties => {
        if (column.depth === 0) {
            return !['client.name', 'experience.name', 'input'].includes(column.id) ? { borderRight: '1px solid gray' } : {};
        }
        if (column.id.match('READY') && (!isCompareMode || column.id.match('compare'))) {
            return {
                borderRight: '1px solid gray',
            };
        }

        if (column.id.match('compare') && !column.id.match('READY') && !column.id.match('input')) {
            return {
                borderRight: '1px solid lightgray',
            };
        }

        return {};
    };

    const getCommonPinningStyles = (column: Column<DashboardRowData>): CSSProperties => {
        const isPinned = column.getIsPinned();
        const isLastLeftPinnedColumn = (isPinned === 'left' && column.getIsLastColumn('left'));

        const offsetIndex = PINNED_COLUMN_NAMES.findIndex((colName) => colName === column.id || (column.id === 'input' && colName === 'input.model'));

        return {
            boxShadow: isLastLeftPinnedColumn || column.id === 'input'
                ? '-4px 0 4px -4px gray inset'
                : undefined,
            left: isPinned === 'left' ? `${offsetIndex !== -1 ? pinnedColOffsets[offsetIndex] : 0}px` : undefined,
            opacity: 1,
            position: isPinned ? 'sticky' : 'relative',
            width: isPinned ? 'auto' : column.getSize(),
            zIndex: isPinned ? 1 : 0,
        };
    };

    const columnHelper = createColumnHelper<DashboardRowData>();

    // ---- Path function to build path for cell click ----
    const baseSearchParams = (clientId: string, experienceId?: string) => `?mode=${searchParams.get('mode')
    }&clientId=${clientId}${(experienceId) ? `&experienceId=${experienceId}` : ''}`;
    const getPPPath = (
        clientId: string,
        experienceId?: string,
        filter?: string | string[],
    ) => {
        if (filter && Array.isArray(filter)) {
            return `/preprocessing${baseSearchParams(clientId, experienceId)}${
                filter.map((one) => `&${one === PREFILTERS.READY ? 'filter' : 'prefilter'}[${GARMENT_STATUS_KEY}][]=${one}`).join('')
            }`;
        }

        return `/preprocessing${baseSearchParams(clientId, experienceId)}${
            filter ? `&${filter === PREFILTERS.READY ? 'filter' : 'prefilter'}[${GARMENT_STATUS_KEY}]=${filter}` : ''}`;
    };
    const getRWPath = (
        clientId: string,
        experienceId?: string,
        filter?: string,
    ) => `/refwarp${baseSearchParams(
        clientId, experienceId,
    )}${filter
        ? `&${([PREFILTERS.READY, PREFILTERS.TOHDQC] as string[]).includes(filter) ? 'filter' : 'prefilter'}[${WARP_STATUS_KEY}]=${filter}` : ''}`;
    const getCWPath = (
        clientId: string,
        experienceId?: string,
        filter?: string,
    ) => `/crosswarp${baseSearchParams(
        clientId, experienceId,
    )}${filter ? `&${filter === PREFILTERS.READY ? 'filter' : 'prefilter'}[${CROSSWARP_STATUS_KEY}]=${filter}` : ''}`;

    const getPreprocessingStatusRowValue = (row: DashboardRowData, status: PREFILTERS) => (
        (row.state.garment.status[status] || 0) - (row.compareState?.garment.status[status] || 0)
    );

    const getWarpStatusRowValue = (row: DashboardRowData, status: PREFILTERS) => (
        (row.state.warp.status[status] || 0) - (row.compareState?.warp.status[status] || 0)
    );

    const getWarpStatusCell = (info: CellContext<DashboardRowData, number>, status: PREFILTERS) => <CellValue
        containerStyle={getCompareCellStyleFromValue(info.getValue())}
        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, status)}
        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
    />;

    const getCrosswarpStatusRowValue = (row: DashboardRowData, status: PREFILTERS) => (
        (row.state.crosswarp.status[status] || 0) - (row.compareState?.crosswarp.status[status] || 0)
    );

    const getCrosswarpStatusCell = (info: CellContext<DashboardRowData, number>, status: PREFILTERS) => <CellValue
        containerStyle={getCompareCellStyleFromValue(info.getValue())}
        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, status)}
        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
    />;

    const defaultColumns = [
        columnHelper.accessor('client.name', {
            cell: (info) => <HStack
                _hover={info.row.getCanExpand() ? { backgroundColor: 'gray.200', cursor: 'pointer' } : undefined}
                onClick={info.row.getToggleExpandedHandler()} p={2} pl={`${info.row.depth === 0 ? 8 : ((info.row.depth + 1) * 16)}px`} pr={2}>
                {info.row.getCanExpand() && <div>{info.row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}</div>}
                <Text>{info.getValue()}</Text>
            </HStack>,
            enableColumnFilter: true,
            header: ({ table }) => <Text
                onClick={table.getToggleAllRowsExpandedHandler()}
            >
                {t('dashboard.client')}
            </Text>,
            id: 'client.name',
        }),
        columnHelper.accessor((row) => row.experience?.name || t('all_experiences', { ns: COMMON_LOCALES }) as string, {
            cell: (info) => <Text pl={2} pr={2}>{info.getValue()}</Text>,
            enableColumnFilter: true,
            header: () => t('dashboard.experience'),
            id: 'experience.name',
        }),
        // ---- INPUT STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor('state.model.total', {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: () => t('model', { ns: COMMON_LOCALES }),
                    id: 'input.model',
                }),
                columnHelper.accessor((row) => (
                    (row.state.model.total || 0) - (row.compareState?.model.total || 0)
                ), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: 'input.model.compare',
                }),
                columnHelper.accessor('state.garment.total', {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: () => t('garment', { ns: COMMON_LOCALES }),
                    id: 'input.garment',
                }),
                columnHelper.accessor((row) => (
                    (row.state.garment.total || 0) - (row.compareState?.garment.total || 0)
                ), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: () => '',
                    id: 'input.garment.compare',
                }),
            ],
            header: () => t('dashboard.input'),
            id: 'input',
        }),
        // ---- PREPROCESSING STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor((row) => (row.state.garment.status[PREFILTERS.TODETAIL] || 0)
                    + (row.state.garment.status[PREFILTERS.TOCLIPPING] || 0)
                    + (row.state.garment.status[PREFILTERS.TOFOREGROUND] || 0)
                    + (row.state.garment.status[PREFILTERS.TOCUT] || 0), {
                    cell: (info) => <CellValue
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, [
                            PREFILTERS.TODETAIL, PREFILTERS.TOCLIPPING, PREFILTERS.TOFOREGROUND, PREFILTERS.TOCUT,
                        ])}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_do', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${[PREFILTERS.TODO]}`,
                }),
                columnHelper.accessor((row) => {
                    const compareValue = (row.compareState?.garment.status[PREFILTERS.TODETAIL] || 0)
                        + (row.compareState?.garment.status[PREFILTERS.TOCLIPPING] || 0)
                        + (row.compareState?.garment.status[PREFILTERS.TOFOREGROUND] || 0)
                        + (row.compareState?.garment.status[PREFILTERS.TOCUT] || 0);

                    const fromValue = (row.state.garment.status[PREFILTERS.TODETAIL] || 0)
                        + (row.state.garment.status[PREFILTERS.TOCLIPPING] || 0)
                        + (row.state.garment.status[PREFILTERS.TOFOREGROUND] || 0)
                        + (row.state.garment.status[PREFILTERS.TOCUT] || 0);

                    return fromValue - compareValue;
                }, {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, [
                            PREFILTERS.TODETAIL, PREFILTERS.TOCLIPPING, PREFILTERS.TOFOREGROUND, PREFILTERS.TOCUT,
                        ])}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: `state.garment.status.compare.${[PREFILTERS.TODO]}`,
                }),
                columnHelper.accessor((row) => row.state.garment.status[PREFILTERS.TOQUALITY] || 0, {
                    cell: (info) => <CellValue
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOQUALITY)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_qc', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${[PREFILTERS.TOQUALITY]}`,
                }),
                columnHelper.accessor((row) => getPreprocessingStatusRowValue(row, PREFILTERS.TOQUALITY), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOQUALITY)}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: `state.garment.status.compare.${[PREFILTERS.TOQUALITY]}`,
                }),
                columnHelper.accessor((row) => row.state.garment.status[PREFILTERS.HASFEEDBACK] || 0, {
                    cell: (info) => <CellValue
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.HASFEEDBACK)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.feedback', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${[PREFILTERS.HASFEEDBACK]}`,
                }),
                columnHelper.accessor((row) => getPreprocessingStatusRowValue(row, PREFILTERS.HASFEEDBACK), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.HASFEEDBACK)}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: `state.garment.status.compare.${[PREFILTERS.HASFEEDBACK]}`,
                }),
                columnHelper.accessor((row) => (row.state.garment.status[PREFILTERS.READY] || 0)
                    + (row.state.garment.status[PREFILTERS.WARPQUALITY] || 0)
                    + (row.state.garment.status[PREFILTERS.TOWARP] || 0), {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const targetValue = info.row.original.state.garment.total;

                        return <CellValue
                            path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.READY)}
                            targetValue={targetValue}
                            value={cellValue}
                        />;
                    },
                    header: t('dashboard.pp_ready', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => {
                    const compareValue = (row.compareState?.garment.status[PREFILTERS.READY] || 0)
                        + (row.compareState?.garment.status[PREFILTERS.WARPQUALITY] || 0)
                        + (row.compareState?.garment.status[PREFILTERS.TOWARP] || 0);

                    const fromValue = (row.state.garment.status[PREFILTERS.READY] || 0)
                        + (row.state.garment.status[PREFILTERS.WARPQUALITY] || 0)
                        + (row.state.garment.status[PREFILTERS.TOWARP] || 0);

                    return fromValue - compareValue;
                }, {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={getCompareCellStyleFromValue(cellValue)}
                            path={getPPPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.READY)}
                            value={`${getComparePrefix(cellValue)}${cellValue}`}
                        />;
                    },
                    header: '',
                    id: `state.garment.status.compare.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => ((
                    (row.state.garment.status[PREFILTERS.READY] || 0)
                    + (row.state.garment.status[PREFILTERS.WARPQUALITY] || 0)
                    + (row.state.garment.status[PREFILTERS.TOWARP] || 0)) * 100) / (row.state.garment.total || 1), {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            targetValue={'100.0%'}
                            value={`${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: t('dashboard.pp_ready', { ns: COMMON_LOCALES }),
                    id: `state.garment.status.${PREFILTERS.READY}.percentage`,
                }),
                columnHelper.accessor((row) => {
                    const compareValue = ((
                        (row.compareState?.garment.status[PREFILTERS.READY] || 0)
                        + (row.compareState?.garment.status[PREFILTERS.WARPQUALITY] || 0)
                        + (row.compareState?.garment.status[PREFILTERS.TOWARP] || 0)) * 100) / (row.compareState?.garment.total || 1);

                    const fromValue = (((row.state.garment.status[PREFILTERS.READY] || 0)
                        + (row.state.garment.status[PREFILTERS.WARPQUALITY] || 0)
                        + (row.state.garment.status[PREFILTERS.TOWARP] || 0)) * 100) / (row.state.garment.total || 1);

                    return fromValue - compareValue;
                }, {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={getCompareCellStyleFromValue(cellValue, true)}
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            value={`${getComparePrefix(cellValue)}${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: '',
                    id: `state.garment.status.compare.${PREFILTERS.READY}.percentage`,
                }),
            ],
            header: t('dashboard.pp_status'),
            id: 'pp_status',
        }),
        // ---- REFWARP STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor((row) => row.state.warp.target, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id)}
                        value={info.getValue()}
                    />,
                    header: t('dashboard.target', { ns: COMMON_LOCALES }),
                    id: 'state.warp.target',
                }),
                columnHelper.accessor((row) => ((row.state.warp.target || 0) - (row.compareState?.warp.target || 0)), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id)}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: 'state.warp.compare.target',
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TODO] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TODO)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_do', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TODO}`,
                }),
                columnHelper.accessor((row) => getWarpStatusRowValue(row, PREFILTERS.TODO), {
                    cell: (info) => getWarpStatusCell(info, PREFILTERS.TODO),
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.TODO}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.MISSINGALLOWED] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.MISSINGALLOWED)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.pending', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.MISSINGALLOWED}`,
                }),
                columnHelper.accessor((row) => getWarpStatusRowValue(row, PREFILTERS.MISSINGALLOWED), {
                    cell: (info) => getWarpStatusCell(info, PREFILTERS.MISSINGALLOWED),
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.MISSINGALLOWED}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TOQUALITY] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOQUALITY)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_qc', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TOQUALITY}`,
                }),
                columnHelper.accessor((row) => getWarpStatusRowValue(row, PREFILTERS.TOQUALITY), {
                    cell: (info) => getWarpStatusCell(info, PREFILTERS.TOQUALITY),
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.TOQUALITY}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TOINTQC] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOINTQC)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.int_qc', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TOINTQC}`,
                }),
                columnHelper.accessor((row) => getWarpStatusRowValue(row, PREFILTERS.TOINTQC), {
                    cell: (info) => getWarpStatusCell(info, PREFILTERS.TOINTQC),
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.TOINTQC}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TOEXTQC] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOEXTQC)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.ext_qc', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TOEXTQC}`,
                }),
                columnHelper.accessor((row) => getWarpStatusRowValue(row, PREFILTERS.TOEXTQC), {
                    cell: (info) => getWarpStatusCell(info, PREFILTERS.TOEXTQC),
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.TOEXTQC}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.TOHDQC] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOHDQC)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.hd_qc', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.TOHDQC}`,
                }),
                columnHelper.accessor((row) => getWarpStatusRowValue(row, PREFILTERS.TOHDQC), {
                    cell: (info) => getWarpStatusCell(info, PREFILTERS.TOHDQC),
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.TOHDQC}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.HASFEEDBACK] || 0, {
                    cell: (info) => <CellValue
                        path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.HASFEEDBACK)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.feedback', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.HASFEEDBACK}`,
                }),
                columnHelper.accessor((row) => getWarpStatusRowValue(row, PREFILTERS.HASFEEDBACK), {
                    cell: (info) => getWarpStatusCell(info, PREFILTERS.HASFEEDBACK),
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.HASFEEDBACK}`,
                }),
                columnHelper.accessor((row) => row.state.warp.status[PREFILTERS.READY] || 0, {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const targetValue = info.row.original.state.warp.target;

                        return <CellValue
                            path={getRWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.READY)}
                            targetValue={targetValue}
                            value={cellValue}
                        />;
                    },
                    header: t('dashboard.rw_ready', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => getWarpStatusRowValue(row, PREFILTERS.READY), {
                    cell: (info) => getWarpStatusCell(info, PREFILTERS.READY),
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => ((row.state.warp.status[PREFILTERS.READY] || 0) * 100) / (row.state.warp.target || 1), {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            targetValue={'100.0%'}
                            value={`${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: t('dashboard.rw_ready', { ns: COMMON_LOCALES }),
                    id: `state.warp.status.${PREFILTERS.READY}.percentage`,
                }),
                columnHelper.accessor((row) => {
                    const compareValue = ((row.compareState?.warp.status[PREFILTERS.READY] || 0) * 100) / (row.compareState?.warp.target || 1);

                    const fromValue = ((row.state.warp.status[PREFILTERS.READY] || 0) * 100) / (row.state.warp.target || 1);

                    return fromValue - compareValue;
                }, {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={getCompareCellStyleFromValue(cellValue, true)}
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            value={`${getComparePrefix(cellValue)}${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: '',
                    id: `state.warp.status.compare.${PREFILTERS.READY}.percentage`,
                }),
            ],
            header: t('dashboard.rw_status'),
            id: 'rw_status',
        }),
        // ---- CROSSWARP STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor((row) => row.state.crosswarp.target, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id)}
                        value={info.getValue()}
                    />,
                    header: t('dashboard.target', { ns: COMMON_LOCALES }),
                    id: 'state.crosswarp.target',
                }),
                columnHelper.accessor((row) => ((row.state.crosswarp.target || 0) - (row.compareState?.crosswarp.target || 0)), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id)}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: 'state.crosswarp.compare.target',
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.MISSINGSIZING] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.MISSINGSIZING)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.sizing', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.MISSINGSIZING}`,
                }),
                columnHelper.accessor((row) => getCrosswarpStatusRowValue(row, PREFILTERS.MISSINGSIZING), {
                    cell: (info) => getCrosswarpStatusCell(info, PREFILTERS.MISSINGSIZING),
                    header: '',
                    id: `state.crosswarp.status.compare.${PREFILTERS.MISSINGSIZING}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.MISSINGOVERRIDE] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.MISSINGOVERRIDE)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_check', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.MISSINGOVERRIDE}`,
                }),
                columnHelper.accessor((row) => getCrosswarpStatusRowValue(row, PREFILTERS.MISSINGOVERRIDE), {
                    cell: (info) => getCrosswarpStatusCell(info, PREFILTERS.MISSINGOVERRIDE),
                    header: '',
                    id: `state.crosswarp.status.compare.${PREFILTERS.MISSINGOVERRIDE}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.MISSINGALLOWED] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.MISSINGALLOWED)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.pending', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.MISSINGALLOWED}`,
                }),
                columnHelper.accessor((row) => getCrosswarpStatusRowValue(row, PREFILTERS.MISSINGALLOWED), {
                    cell: (info) => getCrosswarpStatusCell(info, PREFILTERS.MISSINGALLOWED),
                    header: '',
                    id: `state.crosswarp.status.compare.${PREFILTERS.MISSINGALLOWED}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.TOQUALITY] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOQUALITY)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.to_qc', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.TOQUALITY}`,
                }),
                columnHelper.accessor((row) => getCrosswarpStatusRowValue(row, PREFILTERS.TOQUALITY), {
                    cell: (info) => getCrosswarpStatusCell(info, PREFILTERS.TOQUALITY),
                    header: '',
                    id: `state.crosswarp.status.compare.${PREFILTERS.TOQUALITY}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.TOHDQC] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.TOHDQC)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.hd_qc', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.TOHDQC}`,
                }),
                columnHelper.accessor((row) => getCrosswarpStatusRowValue(row, PREFILTERS.TOHDQC), {
                    cell: (info) => getCrosswarpStatusCell(info, PREFILTERS.TOHDQC),
                    header: '',
                    id: `state.crosswarp.status.compare.${PREFILTERS.TOHDQC}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.HASFEEDBACK] || 0, {
                    cell: (info) => <CellValue
                        path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.HASFEEDBACK)}
                        value={info.getValue()}
                    />,
                    header: t('prefilters.feedback', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.HASFEEDBACK}`,
                }),
                columnHelper.accessor((row) => getCrosswarpStatusRowValue(row, PREFILTERS.HASFEEDBACK), {
                    cell: (info) => getCrosswarpStatusCell(info, PREFILTERS.HASFEEDBACK),
                    header: '',
                    id: `state.crosswarp.status.compare.${PREFILTERS.HASFEEDBACK}`,
                }),
                columnHelper.accessor((row) => row.state.crosswarp.status[PREFILTERS.READY] || 0, {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const targetValue = info.row.original.state.crosswarp.target;
                        const isUnclear = info.row.original.state.crosswarp.unclear;

                        return <CellValue
                            path={getCWPath(info.row.original.client.id, info.row.original.experience?.id, PREFILTERS.READY)}
                            targetValue={targetValue}
                            tooltip={isUnclear ? t('dashboard.tooltip_unclear') : undefined}
                            value={cellValue}
                        />;
                    },
                    header: t('dashboard.cw_ready', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => getCrosswarpStatusRowValue(row, PREFILTERS.READY), {
                    cell: (info) => getCrosswarpStatusCell(info, PREFILTERS.READY),
                    header: '',
                    id: `state.crosswarp.status.compare.${PREFILTERS.READY}`,
                }),
                columnHelper.accessor((row) => (row.state.crosswarp.unclear
                    ? (row.state.crosswarp.status[PREFILTERS.READY] || 0)
                    : ((row.state.crosswarp.status[PREFILTERS.READY] || 0) * 100) / (row.state.crosswarp.target || 1)),
                {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const isUnclear = info.row.original.state.crosswarp.unclear;

                        return <CellValue
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            targetValue={isUnclear ? undefined : '100.0%'}
                            tooltip={isUnclear ? t('dashboard.tooltip_unclear') : undefined}
                            value={isUnclear ? cellValue : `${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: t('dashboard.cw_ready', { ns: COMMON_LOCALES }),
                    id: `state.crosswarp.status.${PREFILTERS.READY}.percentage`,
                }),
                columnHelper.accessor((row) => {
                    const compareValue = ((row.compareState?.crosswarp.status[PREFILTERS.READY] || 0) * 100)
                        / (row.compareState?.crosswarp.target || 1);

                    const fromValue = ((row.state.crosswarp.status[PREFILTERS.READY] || 0) * 100) / (row.state.crosswarp.target || 1);

                    return fromValue - compareValue;
                }, {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={getCompareCellStyleFromValue(cellValue, true)}
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            value={`${getComparePrefix(cellValue)}${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: '',
                    id: `state.crosswarp.status.compare.${PREFILTERS.READY}.percentage`,
                }),
            ],
            header: t('dashboard.cw_status'),
            id: 'cw_status',
        }),
        // ---- FINISHED STATUS ----
        columnHelper.group({
            columns: [
                columnHelper.accessor((row) => row.state.finished.target, {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: t('dashboard.target', { ns: COMMON_LOCALES }),
                    id: 'state.finished.target',
                }),
                columnHelper.accessor((row) => (row.state.finished.target - (row.compareState?.finished.target || 0)), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: 'state.finished.compare.target',
                }),
                columnHelper.accessor((row) => row.state.finished.pose.FRONT || 0, {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: t('FRONT', { ns: COMMON_LOCALES }),
                    id: 'state.finished.pose.FRONT',
                }),
                columnHelper.accessor((row) => ((row.state.finished.pose.FRONT || 0) - (row.compareState?.finished.pose.FRONT || 0)), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: 'state.finished.pose.compare.FRONT',
                }),
                columnHelper.accessor((row) => row.state.finished.pose.BACK || 0, {
                    cell: (info) => <CellValue value={info.getValue()} />,
                    header: t('BACK', { ns: COMMON_LOCALES }),
                    id: 'state.finished.pose.BACK',
                }),
                columnHelper.accessor((row) => ((row.state.finished.pose.BACK || 0) - (row.compareState?.finished.pose.BACK || 0)), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: 'state.finished.pose.compare.BACK',
                }),
                columnHelper.accessor((row) => row.state.finished.ready || 0, {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const targetValue = info.row.original.state.finished.target;
                        const isUnclear = info.row.original.state.finished.unclear;

                        return <CellValue
                            targetValue={targetValue}
                            tooltip={isUnclear ? t('dashboard.tooltip_unclear') : undefined}
                            value={cellValue}
                        />;
                    },
                    header: t('both_finished', { ns: COMMON_LOCALES }),
                    id: 'state.finished.READY',
                }),
                columnHelper.accessor((row) => ((row.state.finished.ready || 0) - (row.compareState?.finished.ready || 0)), {
                    cell: (info) => <CellValue
                        containerStyle={getCompareCellStyleFromValue(info.getValue())}
                        value={`${getComparePrefix(info.getValue())}${info.getValue()}`}
                    />,
                    header: '',
                    id: 'state.finished.compare.READY',
                }),
                columnHelper.accessor((row) => (row.state.finished.unclear
                    ? (row.state.finished.ready || 0)
                    : ((row.state.finished.ready || 0) * 100) / (row.state.finished.target || 1)),
                {
                    cell: (info) => {
                        const cellValue = info.getValue();
                        const isUnclear = info.row.original.state.finished.unclear;

                        return <CellValue
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            targetValue={isUnclear ? undefined : '100.0%'}
                            tooltip={isUnclear ? t('dashboard.tooltip_unclear') : undefined}
                            value={isUnclear ? cellValue : `${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: t('both_finished', { ns: COMMON_LOCALES }),
                    id: 'state.finished.READY.percentage',
                }),
                columnHelper.accessor((row) => {
                    const compareValue = ((row.compareState?.finished.ready || 0) * 100)
                        / (row.compareState?.finished.target || 1);

                    const fromValue = ((row.state.finished.ready || 0) * 100) / (row.state.finished.target || 1);

                    return fromValue - compareValue;
                }, {
                    cell: (info) => {
                        const cellValue = info.getValue();

                        return <CellValue
                            containerStyle={getCompareCellStyleFromValue(cellValue, true)}
                            onClick={() => handleTableHeaderClick(info.column.parent)}
                            value={`${getComparePrefix(cellValue)}${cellValue.toFixed(1)}%`}
                        />;
                    },
                    header: '',
                    id: 'state.finished.compare.READY.percentage',
                }),
            ],
            header: t('dashboard.finished_status'),
            id: 'finished_status',
        }),
    ];
    const table = useReactTable({
        columns: defaultColumns,
        data: dashboardData || [],
        defaultColumn: {
            minSize: 0,
        },
        enableColumnFilters: false,
        filterFromLeafRows: true,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getSubRows: (row) => row.subRows,
        onColumnVisibilityChange: setVisibility,
        onExpandedChange: setExpanded,
        state: {
            columnPinning: { left: PINNED_COLUMN_NAMES },
            columnVisibility: visibility,
            expanded,
            globalFilter: search,
        },
    });

    useEffect(() => {
        if (isDashboardLoading) {
            return undefined;
        }

        if (!tableRef.current) {
            return undefined;
        }

        // ---- Plug resize observer on table to calc the pinned column offets everytime the table resizes ----
        const observer = new ResizeObserver(() => {
            let offset = 0;
            const offsetArray = [];

            // ---- For each pinned column we push the current offset and add the col width to the offset for the next column ----
            for (let i = 0; i < PINNED_COLUMN_NAMES.length; i++) {
                offsetArray.push(offset);
                const element = document.getElementById(PINNED_COLUMN_NAMES[i]);
                offset += element?.clientWidth || 0;
            }

            setPinnedColOffsets(offsetArray);
        });

        observer.observe(tableRef.current);

        return () => {
            observer.disconnect();
        };
    }, [isDashboardLoading]);

    // ---- When we change compare mode we reset the columns visibility ----
    useEffect(() => {
        setVisibility(getDefaultVisibility());
    }, [isCompareMode]);

    // ---- Download an HTML file that can be imported in Excel or Google Sheet ----
    const handleExport = (toDate?: string, fromDate?: string) => {
        // ---- Get all columns not counting the parent ----
        const allFlatColumns = table.getAllLeafColumns();

        // ---- Get columns without compare columns ----
        const columnsWithoutCompare = allFlatColumns.filter((column) => !column.id.match('compare'));

        // ---- We need to fully open the table with the columns we want visible ----
        // ---- Handle Column ----
        if (isCompareMode && !table.getIsAllColumnsVisible()) {
            table.toggleAllColumnsVisible();
        } else if (!isCompareMode && table.getVisibleLeafColumns().length !== columnsWithoutCompare.length) {
            // ---- Build custom visibility for extraction ----
            const newVisibility: { [key: string]: boolean } = {};
            allFlatColumns.forEach((column) => {
                newVisibility[column.id] = !column.id.match('compare');
            });

            // ---- Assign it ----
            table.setColumnVisibility(newVisibility);
        }

        // ---- Handle Rows ----
        if (!table.getIsAllRowsExpanded()) {
            table.toggleAllRowsExpanded();
        }

        // ---- We need and interval as it takes time to open all the columns and rows ----
        let intervalTimeout = 0;
        const interval = window.setInterval(() => {
            // ---- If we have not yet everything we want opened or if we hit the timeout threshold ---
            if ((
                !table.getIsAllRowsExpanded()
                || (isCompareMode && !table.getIsAllColumnsVisible())
                || (!isCompareMode && table.getVisibleLeafColumns().length !== columnsWithoutCompare.length)
            ) && intervalTimeout < 20) {
                intervalTimeout++;

                return;
            }

            clearInterval(interval);

            if (intervalTimeout >= 10) {
                toast({
                    isClosable: true,
                    status: 'error',
                    title: t('export_dashboard', { ns: ERROR_LOCALES }),
                });

                return;
            }

            // ---- Get Table HTMLElement ----
            const tableElem = document.getElementById('state-table') as HTMLElement | null;
            if (!tableElem) {
                toast({
                    isClosable: true,
                    status: 'error',
                    title: t('export_dashboard', { ns: ERROR_LOCALES }),
                });

                return;
            }

            // ---- Extract HTML content ----
            const tableHtml = tableElem.innerHTML;

            // ---- Create blob used to make a downloadable content ----
            const blob = new Blob([tableHtml], { type: 'text/html' });

            // ---- Create data URL from blob ----
            const url = window.URL.createObjectURL(blob);

            // ---- Need to create this "a" tag to trigger the download ----
            const link = document.createElement('a');
            link.href = url;
            // ---- Var for date in file name ----
            const currentDate = new Date();
            const day = currentDate.getUTCDate().toString().padStart(2, '0');
            const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getUTCFullYear();
            link.download = `IGP_report_${
                fromDate || ''}${
                toDate ? `${fromDate ? '_to_' : ''}${toDate}` : ''}${
                !fromDate && !toDate ? `${day}-${month}-${year}` : ''
            }.html`;
            link.click();

            // ---- Clean ----
            window.URL.revokeObjectURL(url);

            // ---- Reset table ----
            table.setColumnVisibility(getDefaultVisibility());
            table.toggleAllRowsExpanded();
        }, 200);
    };

    React.useImperativeHandle(
        ref,
        () => ({
            exportHtml(toDate?: string, fromDate?: string) {
                handleExport(toDate, fromDate);
            },
        }),
    );

    return (

        isDashboardLoading
            ? <HStack alignItems={'center'} h="100%" justifyContent={'center'} w="100%"><Spinner /></HStack>
            : <TableContainer h="100%" id="state-table" overflowX="scroll" overflowY="unset">
                <Table ref={tableRef} size="sm" variant="unstyled">
                    <Thead bgColor="gray.200" position="sticky" top="0px" zIndex={2}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th
                                        _hover={
                                            ['experience.name', 'input'].includes(header.column.id) || header.isPlaceholder
                                                ? undefined
                                                : { backgroundColor: 'gray.300', cursor: 'pointer' }}
                                        bgColor='gray.200'
                                        colSpan={header.colSpan}
                                        color={header.depth !== 1 && header.column.id.match('READY') ? 'black' : 'unset'}
                                        id={header.id}
                                        key={header.id}
                                        onClick={() => handleTableHeaderClick(header.column)}
                                        p={2}
                                        style={{
                                            ...getCommonPinningStyles(header.column),
                                            ...getStyleBorderStyle(header.column),
                                        }}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                        {header.depth !== 1 && getSortIcon(header.column.getIsSorted())}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr
                                _hover={{ backgroundColor: 'gray.100' }}
                                bgColor="white"
                                borderBottom={`1px solid ${(row.depth === 0 && !row.getIsExpanded())
                                    || (row.getParentRow()?.subRows.length === row.index + 1)
                                    ? 'gray'
                                    : 'lightgray'
                                }`}
                                key={row.id}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <Td
                                        bgColor={PINNED_COLUMN_NAMES.includes(cell.column.id) ? 'inherit' : undefined}
                                        fontWeight={cell.column.id === 'client.name' ? 'bold' : 'normal'}
                                        key={cell.id}
                                        p={0}
                                        style={{
                                            ...getCommonPinningStyles(cell.column),
                                            ...getStyleBorderStyle(cell.column),
                                        }}
                                        textAlign={!PINNED_COLUMN_NAMES.includes(cell.column.id) ? 'end' : 'start'}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
    );
});

export default DashboardTable;
