import { Box, Center } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckFinalSubHeader from '../../components/headers/CheckFinalSubHeader';
import GridLayout from '../../components/layouts/GridLayout';
import { useGetChecksMutation } from '../../services/api/api-check';
import { GarmentMutationTrigger } from '../../services/api/api-garment';
import { getClient, getConfig } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { ERROR_LOCALES, GARMENTS_LOCALES } from '../../utils/constants';
import CheckGridElement from './CheckGridElement';

export default function CheckFinal() {
    const { t } = useTranslation([GARMENTS_LOCALES, ERROR_LOCALES]);

    const config = useAppSelector((state) => getConfig(state));
    const currentClient = useAppSelector((state) => getClient(state));

    const [getChecksTrigger, { isLoading: isCheckGarmentsLoading }] = useGetChecksMutation();

    return (
        <Box h="100%">
            {
                !currentClient
                    ? <Center height="100%">{t('select_client', { ns: ERROR_LOCALES })}</Center>
                    : <GridLayout
                        FlexWarpComponent={CheckGridElement}
                        SubHeaderContent={CheckFinalSubHeader}
                        dataTrigger={getChecksTrigger as GarmentMutationTrigger}
                        imagesRatio={
                            config
                                ? `${(100 / config.garment_image_ratio).toString()}%`
                                : '120%'
                        }
                        isLoading={isCheckGarmentsLoading}
                        noReservationQuery={true}
                        title={t('title', { clientName: currentClient?.name })}
                        type="garment"
                    />
            }
        </Box>
    );
}
