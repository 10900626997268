import { CheckCircleIcon, InfoIcon } from '@chakra-ui/icons';
import { Button, Tooltip } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { resetPageFilters } from '../services/store/slices/sessionSlice';
import preventCmdCtrlClick from '../utils/click-helpers';
import useCustomNavigate from '../utils/custom-navigate-hook';

interface CellValueProps {
    value?: number | string,
    path?: string,
    containerStyle?: React.CSSProperties
    onClick?(): void
    targetValue?: number | string
    tooltip?: string
}

/*
 * Table Cell component with click used for dashboard
 * @param props Value and navigation Path
 * @returns
 */
export default function CellValue(props: CellValueProps) {
    const { value, path, containerStyle, onClick, targetValue, tooltip } = props;
    const navigate = useCustomNavigate();
    const dispatch = useDispatch();

    const rightIcon = useMemo(() => {
        if (tooltip) {
            return <InfoIcon />;
        }

        if (targetValue === value) {
            return <CheckCircleIcon color="state.success"/>;
        }

        return undefined;
    }, [targetValue, value, tooltip]);

    const handleOnClick = () => {
        // ----- OnClick overrides the default behaviour -----
        if (onClick) {
            onClick();

            return;
        }

        if (!path) {
            return;
        }

        // ---- Need to reset the store first ----
        const urlPath = path.replaceAll(/[?].*/g, '');
        dispatch(resetPageFilters(urlPath));

        navigate(path);
    };

    return (
        <Tooltip label={tooltip} openDelay={0}>
            <Button
                _hover={path || onClick ? { backgroundColor: 'gray.200', cursor: 'pointer' } : undefined}
                as="a"
                borderRadius={0}
                display="flex"
                h="100%"
                href={path}
                justifyContent="flex-end"
                onClick={(e) => preventCmdCtrlClick(e, handleOnClick)}
                p={2}
                rightIcon={rightIcon}
                style={containerStyle}
                variant='unstyled'
                w="100%"
            >
                {value || 0}
            </Button>

        </Tooltip>

    );
}
