import { Center } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CrossWarpSubHeader from '../../components/headers/CrossWarpSubHeader';
import GridLayout from '../../components/layouts/GridLayout';
import { GarmentMutationTrigger } from '../../services/api/api-garment';
import { useGetCrossWarpsMutation } from '../../services/api/api-warp';
import {
    getClient,
    getConfig,
    getIsCrosswarpView,
} from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { DEFAULT_CROSSWARP_FILTERS, ERROR_LOCALES, GARMENTS_LOCALES } from '../../utils/constants';
import GarmentGridElement from '../garments/GarmentGridElement';
import CrosswarpGridElement from './CrosswarpGridElement';

export default function CrosswarpGrid() {
    const { t } = useTranslation(GARMENTS_LOCALES);

    const currentClient = useAppSelector((state) => getClient(state));
    const config = useAppSelector((state) => getConfig(state));
    const isCrossView = useAppSelector((state) => getIsCrosswarpView(state));

    const [getCrosswarpTrigger, { isLoading: isCrosswarpLoading }] = useGetCrossWarpsMutation();

    return (
        <>
            {
                !currentClient
                    ? <Center height="100%">{t('select_client', { ns: ERROR_LOCALES })}</Center>
                    : <GridLayout
                        FlexWarpComponent={isCrossView ? CrosswarpGridElement : GarmentGridElement}
                        SubHeaderContent={CrossWarpSubHeader}
                        dataTrigger={getCrosswarpTrigger as GarmentMutationTrigger}
                        defaultFilters={DEFAULT_CROSSWARP_FILTERS}
                        imagesRatio={
                            config
                                ? `${(100 / (isCrossView ? config.look_image_ratio : config.garment_image_ratio)).toString()}%`
                                : '120%'
                        }
                        isLoading={isCrosswarpLoading}
                        // ---- Use key to reset grid when we change the crossview ----
                        key={isCrossView.toString()}
                        title={t('title', { clientName: currentClient?.name })}
                        type="garment"
                    />
            }
        </>
    );
}
