import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeedbackImagesContext } from '../components/FeedbackImagesContext';
import { FeedbackImage, KeypointType, Pose, Sample } from '../types/api-types';
import { PREPROCESSING_LOCALES } from './constants';

interface FeedbackContextHelperProps {
    label?: string,
    imageSrc?: string | string[],
    superpose?: string | string[],
    superposeLabel?: string,
    imagesData?: (string | Pose)[],
    overlay?: string[],
    opacityValue?: number,
    gridSample?: Sample[],
    kpList?: KeypointType[],
}

export default function useFeedbackContextHelper(props: FeedbackContextHelperProps) {
    const FeedbackImagesContext = useFeedbackImagesContext();
    const { t } = useTranslation(PREPROCESSING_LOCALES);
    const {
        imageSrc,
        superpose,
        superposeLabel,
        imagesData,
        overlay,
        label,
        opacityValue,
        gridSample,
        kpList,
    } = props;

    // ---- Check in the srcArray if we have the src we want to check ----
    const checkMatchSrc = (srcCheck: string, srcArray?: string[]) => {
        if (!srcArray) {
            return false;
        }

        let result = false;
        for (let i = 0; i < srcArray.length; i++) {
            if (srcCheck === srcArray[i]) {
                result = true;
                break;
            }
        }

        return result;
    };

    useEffect(() => {
        if (!FeedbackImagesContext) {
            return;
        }

        // ---- Var used to handle duplicate with superpose srcs ----
        const localImagesSrc: string[] = [];

        // ---- Handle imageSrc prop ----
        if (imageSrc) {
            if (typeof imageSrc === 'string') {
                localImagesSrc.push(imageSrc);
                FeedbackImagesContext.addFeedbackImage({
                    feedbackSuperpose: checkMatchSrc(imageSrc, FeedbackImagesContext.feedbackAllowSuperposeSrc),
                    kpList,
                    label,
                    opacityValue,
                    src: imageSrc,
                    superpose: overlay,
                });
            } else {
                imageSrc.forEach((img, i) => {
                    localImagesSrc.push(img);
                    FeedbackImagesContext.addFeedbackImage(
                        {
                            feedbackSuperpose: checkMatchSrc(img, FeedbackImagesContext.feedbackAllowSuperposeSrc),
                            kpList,
                            label,
                            src: img,
                            superpose: overlay ? overlay[i] : undefined,
                        },
                    );
                });
            }
        }

        // ---- Handle imagesData prop ----
        if (imagesData) {
            imagesData.forEach((imgData) => {
                const imgSrc = typeof imgData === 'string' ? imgData : imgData.image_url;
                localImagesSrc.push(imgSrc);
                FeedbackImagesContext.addFeedbackImage(
                    {
                        feedbackSuperpose: checkMatchSrc(imgSrc, FeedbackImagesContext.feedbackAllowSuperposeSrc),
                        label,
                        src: imgSrc,
                    },
                );
            });
        }

        // ---- Handle gridSample prop ----
        if (gridSample && FeedbackImagesContext.changeGridFeedbackImages) {
            const localGridSampleResult: FeedbackImage[] = [];
            gridSample.forEach((sample) => {
                localImagesSrc.push(sample.image_url);
                localGridSampleResult.push(
                    {
                        feedbackSuperpose: checkMatchSrc(sample.image_url, FeedbackImagesContext.feedbackAllowSuperposeSrc),
                        label,
                        src: sample.image_url,
                    },
                );
            });

            // ---- We update gridFeedbackImages to not override the existing ones already as it's asynchronous ----
            FeedbackImagesContext.changeGridFeedbackImages(localGridSampleResult);
        }

        // ---- Handle superpose prop ----
        if (superpose) {
            // ---- We don't add the superpose in the feedback image array if it's already present ----
            if ((typeof superpose === 'string' && localImagesSrc.includes(superpose))) {
                return;
            }

            // ---- We do the same for the array of superpose ----
            if (typeof superpose !== 'string') {
                let isAlreadyUsed = false;

                superpose.forEach((superposeSrc) => {
                    if (localImagesSrc.includes(superposeSrc)) {
                        isAlreadyUsed = true;
                    }
                });

                if (isAlreadyUsed) {
                    return;
                }
            }

            // ---- We add the superpose if it's not a duplicate ----
            FeedbackImagesContext.addFeedbackImage(
                {
                    feedbackSuperpose: typeof superpose === 'string'
                        ? checkMatchSrc(superpose, FeedbackImagesContext.feedbackAllowSuperposeSrc)
                        : undefined,
                    label: superposeLabel || t('superpose', { ns: PREPROCESSING_LOCALES }),
                    src: superpose,
                    superpose: overlay,
                },
            );
        }
    }, [imageSrc, opacityValue, gridSample]);
}
