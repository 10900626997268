import { Button, HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFilterValue, FacetTerms } from '../../types/api-types';
import { COMMON_LOCALES } from '../../utils/constants';
import { useFiltersContext } from '../filters/FiltersContext';
import { FilterInContextType } from '../filters/filtersTypes';

interface PreFilterButtonProps {
    label: string,
    filterKey: string,
    values: (string | CustomFilterValue)[],
    currentFacet?: FacetTerms,
    initFacet?: FacetTerms,
    overrideDisabled?: boolean,
    children?: ReactNode
    containerStyle?: StackProps
}

// ---- Button used to prefilter directly in the Grid View. NEEDS THE FILTERCONTEXT ----
export default function PreFilterButton(props: PreFilterButtonProps) {
    const FilterContext = useFiltersContext();
    const { t } = useTranslation(COMMON_LOCALES);

    const { label, filterKey, values, currentFacet, overrideDisabled, initFacet, children, containerStyle } = props;

    const calcFacetCount = (facet: FacetTerms) => {
        let result = 0;
        facet.data.forEach((facetData) => {
            if (values.includes(facetData.value)) {
                result += facetData.count;
            }
        });

        return result;
    };

    // ---- Total without filter ----
    const totalCount = useMemo(() => {
        if (!initFacet) {
            return 0;
        }

        return calcFacetCount(initFacet);
    }, [initFacet]);

    // ---- Total of current Facet ----
    const currentCount = useMemo(() => {
        if (!currentFacet) {
            return 0;
        }

        return calcFacetCount(currentFacet);
    }, [currentFacet]);

    // ---- Check in Context if Prefilter is Activated ----
    const isActivated = useMemo(() => {
        let result = true;
        for (let i = 0; i < values.length; i++) {
            // ---- Local var init ----
            let localFilterKey = filterKey;
            let localValue = values[i];

            // ---- Check if we have a custom filter type ----
            if (typeof values[i] !== 'string') {
                // ---- Cast the value to correct type ----
                const customFilterValue = values[i] as CustomFilterValue;

                // ---- Assign custom values to local vars ----
                localFilterKey = customFilterValue.customKey;
                localValue = customFilterValue.value;
            }

            if (
                !FilterContext
                || FilterContext.prefilters?.findIndex(
                    (contextFilter) => contextFilter.filterKey === localFilterKey
                        && contextFilter.filterValue === localValue,
                ) === -1
            ) {
                result = false;
                break;
            }
        }

        return result;
    }, [FilterContext?.prefilters]);

    const newFilterValues: FilterInContextType[] = useMemo(() => values.map((newValue) => {
        if (typeof newValue === 'string') {
            return { filterKey, filterValue: newValue };
        }

        return { filterKey: newValue.customKey, filterValue: newValue.value };
    }), [values]);

    // ---- On click we update the prefilters array of empty it ----
    const handleFilterClick = () => {
        if (!FilterContext || !FilterContext.updatePrefilters) {
            return;
        }

        FilterContext.updatePrefilters(isActivated ? [] : newFilterValues);
    };

    const withChildrenStyle: StackProps = {
        bgColor: '#F7FBFF',
        pr: 1,
        spacing: 2,
    };

    return (
        <HStack borderRadius="full" h='40px' {...(children ? withChildrenStyle : null)} {...containerStyle}>
            <Button
                h="100%"
                isActive={isActivated}
                isDisabled={(!isActivated && currentCount === 0) || overrideDisabled}
                onClick={handleFilterClick}
                variant="tab-like"
            >
                <VStack spacing={0}>
                    <Text fontSize={15}>{label} ({currentCount})</Text>
                    {FilterContext && FilterContext.filters.length > 0 && <Text color="gray.400" fontSize={11}>{t('total')}: {totalCount}</Text>}
                </VStack>
            </Button>
            {children}
        </HStack>
    );
}
