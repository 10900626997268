import { Box, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import GridLayout from '../../components/layouts/GridLayout';
import { useGetExternalsMutation } from '../../services/api/api-external';
import { GarmentMutationTrigger } from '../../services/api/api-garment';
import { getClient, getConfig } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { ErrorMutation } from '../../types/api-types';
import { ERROR_LOCALES, GARMENTS_LOCALES, WARP_LOCALES } from '../../utils/constants';
import CheckGridElement from '../check/CheckGridElement';

export default function External() {
    const { t } = useTranslation([GARMENTS_LOCALES, ERROR_LOCALES, WARP_LOCALES]);

    const config = useAppSelector((state) => getConfig(state));
    const currentClient = useAppSelector((state) => getClient(state));

    const [getExternalsTrigger, { data, error: errorExtQuality, isLoading: isExternalLoading }] = useGetExternalsMutation();

    return (
        <Box h="100%">
            {
                errorExtQuality
                    ? <VStack h="100%" justifyContent="center">
                        <Text>{(errorExtQuality as ErrorMutation)?.data?.message}</Text>
                    </VStack>
                    : <>
                        {
                            data?.items.length === 0
                                ? <VStack h="100%" justifyContent="center">
                                    <Text fontSize="60px" fontWeight="bold">{t('no_external_garments_title', { ns: WARP_LOCALES })}</Text>
                                    <Text>{t('no_external_garments', { ns: WARP_LOCALES })}</Text>
                                </VStack>
                                : <GridLayout
                                    FlexWarpComponent={CheckGridElement}
                                    dataTrigger={getExternalsTrigger as GarmentMutationTrigger}
                                    imagesRatio={
                                        config
                                            ? `${(100 / config.look_image_ratio).toString()}%`
                                            : '120%'
                                    }
                                    isLoading={isExternalLoading}
                                    noReservationQuery={true}
                                    title={t('title', { clientName: currentClient?.name })}
                                    type="garment"
                                />
                        }
                    </>

            }

        </Box>
    );
}
