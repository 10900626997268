import { Center } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import GridLayout from '../../components/layouts/GridLayout';
import ProductsContext from '../../components/products/ProductsContext';
import { useGetGarmentsMutation } from '../../services/api/api-garment';
import { getClient, getConfig } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Facets } from '../../types/api-types';
import { DEFAULT_PREPROCESSING_FILTERS, DEFAULT_REFWARP_FILTERS, ERROR_LOCALES, GARMENTS_LOCALES } from '../../utils/constants';
import GarmentGridElement from './GarmentGridElement';

interface GarmentsGridProps {
    SubHeaderContent?: React.ElementType<{ facets?: Facets }>,
}

export default function GarmentsGrid(props: GarmentsGridProps) {
    const { t } = useTranslation(GARMENTS_LOCALES);
    const { SubHeaderContent } = props;
    const { pathname } = useLocation();

    const currentClient = useAppSelector((state) => getClient(state));
    const config = useAppSelector((state) => getConfig(state));

    // ---- Use the default filter according to the pathname so we hide unrelevant garment ----
    const defaultFilters = useMemo(() => {
        switch (pathname) {
            case '/preprocessing':
                return DEFAULT_PREPROCESSING_FILTERS;
            case '/refwarp':
                return DEFAULT_REFWARP_FILTERS;
            default:
                return undefined;
        }
    }, [pathname]);

    const [getGarmentTrigger, { isLoading }] = useGetGarmentsMutation();

    // ---- ProductsContext var and functions ----
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = useState<{ id: string }[]>([]);
    const selectAProduct = (id: string) => setSelectedProducts([...selectedProducts, { id }]);
    const deselectAProduct = (id: string) => {
        if (checkAll) {
            setCheckAll(false);

            return setSelectedProducts([]);
        }

        return setSelectedProducts(selectedProducts.filter((product) => product.id !== id));
    };
    const changeCheckAll = (newVal: boolean) => {
        if (!newVal) {
            setSelectedProducts([]);
        }
        setCheckAll(newVal);
    };

    return (
        <ProductsContext.Provider
            value={{
                changeCheckAll,
                checkAll,
                deselectAProduct,
                selectAProduct,
                selectedProducts,
            }}
        >
            {
                !currentClient
                    ? <Center height="100%">{t('select_client', { ns: ERROR_LOCALES })}</Center>
                    : <GridLayout
                        FlexWarpComponent={GarmentGridElement}
                        SubHeaderContent={SubHeaderContent}
                        dataTrigger={getGarmentTrigger}
                        defaultFilters={defaultFilters}
                        imagesRatio={
                            config
                                ? `${(100 / config.garment_image_ratio).toString()}%`
                                : '120%'
                        }
                        isLoading={isLoading}
                        title={t('title', { clientName: currentClient?.name })}
                        type="garment"
                    />
            }

        </ProductsContext.Provider>

    );
}
