import { HStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Facets, FacetTerms, Prefilter } from '../../types/api-types';
import { COMMON_LOCALES, GARMENT_FEEDBACK_PRIORITY, GARMENT_STATUS_KEY, PREFILTERS, TODO_VALUES } from '../../utils/constants';
import PreFilterButton from '../buttons/PreFilterButton';

const PreprocessingSubHeader = (props: { facets?: Facets, initFacets?: Facets }) => {
    // const ProductContext = useProductsContext();
    const { t } = useTranslation([COMMON_LOCALES]);

    const { facets, initFacets } = props;

    const prefilters: Prefilter[] = [
        { label: t('prefilters.to_do', { ns: COMMON_LOCALES }), values: TODO_VALUES },
        { label: t('prefilters.to_qc', { ns: COMMON_LOCALES }), values: [PREFILTERS.TOQUALITY] },
        {
            label: t('prefilters.has_feedback', { ns: COMMON_LOCALES }),
            subfilters: [
                {
                    filterKey: GARMENT_FEEDBACK_PRIORITY,
                    label: t('feedback.priority.short_1', { ns: COMMON_LOCALES }),
                    values: [PREFILTERS.P1, PREFILTERS.P2],
                },
                {
                    filterKey: GARMENT_FEEDBACK_PRIORITY,
                    label: t('feedback.priority.short_9', { ns: COMMON_LOCALES }),
                    values: [PREFILTERS.P9],
                },
            ],
            values: [PREFILTERS.HASFEEDBACK],
        },
    ];

    return (
        <HStack justifyContent="space-between" w="100%">
            <HStack>
                {
                    facets && prefilters.map((prefilter, index) => (
                        <PreFilterButton
                            currentFacet={facets[prefilter.filterKey || GARMENT_STATUS_KEY] as FacetTerms}
                            filterKey={prefilter.filterKey || GARMENT_STATUS_KEY}
                            initFacet={initFacets ? initFacets[prefilter.filterKey || GARMENT_STATUS_KEY] as FacetTerms : undefined}
                            key={index + prefilter.label}
                            label={prefilter.label}
                            overrideDisabled={!(facets[prefilter.filterKey || GARMENT_STATUS_KEY] as FacetTerms)}
                            values={prefilter.values}
                        >
                            {
                                prefilter.subfilters && <HStack>
                                    {
                                        prefilter.subfilters.map((subfilter) => <PreFilterButton
                                            containerStyle={{ h: '34px' }}
                                            currentFacet={facets[subfilter.filterKey || GARMENT_STATUS_KEY] as FacetTerms}
                                            filterKey={subfilter.filterKey || GARMENT_STATUS_KEY}
                                            initFacet={initFacets ? initFacets[subfilter.filterKey || GARMENT_STATUS_KEY] as FacetTerms : undefined}
                                            key={subfilter.label}
                                            label={subfilter.label}
                                            overrideDisabled={
                                                subfilter.isDisabled
                                                || !(facets[subfilter.filterKey || GARMENT_STATUS_KEY] as FacetTerms)
                                            }
                                            values={subfilter.values}
                                        />)
                                    }
                                </HStack>
                            }

                        </PreFilterButton>
                    ))
                }
            </HStack>

            {/* <VStack alignItems={'flex-end'}>
                <div>
                    {t('grid.selected')}{ProductContext?.checkAll ? t('grid.all') : ProductContext?.selectedProducts.length}
                </div>
                {
                    ProductContext
            && <HStack spacing={8}>
                <Button onClick={() => ProductContext.changeCheckAll(true)} variant='link'>
                    {t('grid.select_all')}
                </Button>
                <Button onClick={() => ProductContext.changeCheckAll(false)} variant='link'>
                    {t('grid.reset')}
                </Button>
            </HStack>
                }
            </VStack> */}
        </HStack>

    );
};

export default PreprocessingSubHeader;
