import { createContext, useContext } from 'react';

import { FeedbackImage } from '../types/api-types';

// ---- Context used for the new Feedback Modal ----
export interface FeedbackImagesContextType {
    feedbackImages: FeedbackImage[],
    gridFeedbackImages?: FeedbackImage[], // we need a separate var that we reset when something else than step needs to add images
    addFeedbackImage(newImage: FeedbackImage): void,
    changeGridFeedbackImages?(newImages: FeedbackImage[]): void
    feedbackAllowSuperposeSrc?: string[]
}

const FeedbackImagesContext = createContext<FeedbackImagesContextType | null>(null);

export const useFeedbackImagesContext = () => useContext(FeedbackImagesContext);

export default FeedbackImagesContext;
