import { Center, HStack, Spinner, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';

import { GarmentMutationTrigger } from '../../services/api/api-garment';
import { useGetReservationMutation } from '../../services/api/api-warp';
import {
    addReservation,
    getClient,
    getExperience,
    getIsCrosswarpView,
    getPageFilters,
    resetReservation,
    setPageFiltersFilters,
    setPageFiltersPrefilters,
} from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Facets, Filters, Pagination, Reservation } from '../../types/api-types';
import { COMMON_LOCALES, DEFAULT_IMAGESIZE_KEY } from '../../utils/constants';
import { loadFromLocalStorage } from '../../utils/local-storage-helpers';
import handlePaginationScroll from '../../utils/scroll-pagination-helpers';
import { addFilterToKeyUrlParams, getFiltersFromUrlParams, getPrefiltersFromUrlParams, removeKeysFromUrlParams } from '../../utils/url-helpers';
import FiltersContext from '../filters/FiltersContext';
import FiltersPanel from '../filters/FiltersPanel';
import { FilterInContextType } from '../filters/filtersTypes';
import GridSubHeader from '../headers/GridSubHeader';

interface GridLayoutProps {
    title: string,
    FlexWarpComponent: React.ElementType,
    imagesRatio: string,
    isLoading: boolean,
    type: string,
    SubHeaderContent?: React.ElementType<{ facets?: Facets, initFacets?: Facets }>,
    noReservationQuery?: boolean
    dataTrigger: GarmentMutationTrigger
    defaultFilters?: Filters
}

let timeoutSearchId: ReturnType<typeof setTimeout> | null = null;

export default function GridLayout(props: GridLayoutProps) {
    const { t } = useTranslation(COMMON_LOCALES);
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const [, setSearchParams] = useSearchParams();

    const {
        title,
        FlexWarpComponent,
        imagesRatio,
        isLoading,
        type,
        SubHeaderContent,
        noReservationQuery,
        dataTrigger,
        defaultFilters,
    } = props;

    // const ProductContext = useProductsContext();

    const storePageFilters = useAppSelector((state) => getPageFilters(state, pathname));
    const currentClient = useAppSelector((state) => getClient(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));
    const isCrosswarpView = useAppSelector((state) => getIsCrosswarpView(state));

    const [selectedFilters, setSelectedFilters] = useState<FilterInContextType[]>(storePageFilters?.filters || []);
    const [queryString, setQueryString] = useState<string>('');
    const [prefilters, setPrefilters] = useState<FilterInContextType[]>([]);
    const [prefiltersFacets, setPrefiltersFacets] = useState<Facets | undefined>();

    const [localData, setLocalData] = useState<Pagination>();
    const [initFacets, setInitFacets] = useState<Facets>();

    // Init value needs to be the same as the slider in the GridSubbHeader
    const [numberProductPerRow, setNumerProductPerRow] = useState<number>(loadFromLocalStorage(DEFAULT_IMAGESIZE_KEY) || 4);

    // Use this instead of queryResponse so there is no blink
    const [fullProducts, setFullProducts] = useState<unknown[] | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentTotalProduct, setCurrentTotalProduct] = useState<number>(0);

    // Method to add a filter in the array
    const addFilterToArray = (filters: FilterInContextType[], newFilter: FilterInContextType) => [...filters, newFilter];

    // Those methods are used for the Filter Context to handle the selection outside the filter components
    const getSelectedFilters = (filters: FilterInContextType[]) => {
        const filterObject: Filters = {};
        filters.forEach((filter) => {
            if (filterObject[filter.filterKey]) {
                if (Array.isArray(filterObject[filter.filterKey])) {
                    filterObject[filter.filterKey] = [...filterObject[filter.filterKey] as string[], filter.filterValue];
                } else {
                    filterObject[filter.filterKey] = [filterObject[filter.filterKey] as string, filter.filterValue];
                }
            } else {
                filterObject[filter.filterKey] = filter.filterValue;
            }
        });

        return filterObject;
    };

    const removeFilterFromArray = (filters: FilterInContextType[], newFilter: FilterInContextType) => {
        const newFilters = filters.filter(
            (filter) => filter.filterKey !== newFilter.filterKey || filter.filterValue !== newFilter.filterValue,
        );

        return newFilters;
    };

    const resetTotalValues = () => {
        setCurrentTotalProduct(0);
    };

    // ---- Handle new prefilters in URL ----
    const updatePrefilterUrlParams = (newFilters: FilterInContextType[]) => {
        if (newFilters) {
            setSearchParams(addFilterToKeyUrlParams(search, newFilters, 'prefilter'), { replace: true });

            return;
        }
        setSearchParams(removeKeysFromUrlParams(search, ['prefilter']), { replace: true });
    };

    const fetchNewFilters = (updatedFilters: FilterInContextType[], query = '', page = 1, overridePrefilters?: FilterInContextType[]) => {
        const selectedPrefilters = (overridePrefilters || prefilters);

        // ---- group_garment value for API call of crosswarps ----
        const groupGarment = matchPath('/crosswarp', pathname) && !isCrosswarpView ? 1 : undefined;

        // ---- Update filters and prefilters at the same time in URL ----
        let searchParamsWithFilters = updatedFilters.length === 0
            ? removeKeysFromUrlParams(search, ['filter'])
            : addFilterToKeyUrlParams(search, updatedFilters, 'filter');

        searchParamsWithFilters = selectedPrefilters.length === 0
            ? removeKeysFromUrlParams(searchParamsWithFilters, ['prefilter'])
            : addFilterToKeyUrlParams(searchParamsWithFilters, selectedPrefilters, 'prefilter');

        setSearchParams(searchParamsWithFilters, { replace: true });

        // ---- Update state ----
        setSelectedFilters(updatedFilters);
        setPrefilters(overridePrefilters || prefilters);
        setCurrentPage(page);

        // ---- Update store Page Filters and Prefilters ----
        dispatch(setPageFiltersFilters({ key: pathname, newActiveFilters: updatedFilters.length === 0 ? undefined : updatedFilters }));
        dispatch(setPageFiltersPrefilters({ key: pathname, newPrefilters: selectedPrefilters.length === 0 ? undefined : selectedPrefilters }));

        // ---- Get new filters objct needed for the requests ----
        const filters = getSelectedFilters([...updatedFilters, ...selectedPrefilters]);
        const filtersWithoutPrefilters = getSelectedFilters(updatedFilters);

        if (!currentClient) {
            return;
        }

        // ---- API Call with all filters ----
        dataTrigger(
            {
                clientId: currentClient.id,
                experienceId: selectedExperience?.id,
                filters: { ...defaultFilters, ...filters },
                group_garment: groupGarment,
                page,
                query,
            },
        ).unwrap().then((data) => {
            const apiCurrentPage = parseInt(data.current_page_number, 10);

            if (apiCurrentPage !== 1) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setFullProducts((prev) => prev.concat(data.items));
            } else {
                setCurrentPage(1);
                setCurrentTotalProduct(data.total_dedup_count || data.total_count);
                setFullProducts(data.items);
            }
            setLocalData(data);
        });

        // ---- API Call WITHOUT Prefilters to update counter facets (needs separate because we need the only facets param)----
        dataTrigger(
            {
                clientId: currentClient.id,
                experienceId: selectedExperience?.id,
                filters: { ...defaultFilters, ...filtersWithoutPrefilters },
                group_garment: groupGarment,
                only_facets: 1,
                page,
                query,
            },
        ).unwrap().then((data) => {
            setPrefiltersFacets(data.facets);
        });
    };

    const selectAFilter = (newFilter: FilterInContextType, query = queryString, page = 1) => {
        const newFilters = addFilterToArray([...selectedFilters], newFilter);
        resetTotalValues();
        fetchNewFilters(newFilters, query, page);
    };

    const selectMultipleFilters = (newFilters: FilterInContextType[], query = queryString, page = 1) => {
        let localSelectedFilters = [...selectedFilters];
        newFilters.forEach((newFilter) => {
            localSelectedFilters = addFilterToArray(localSelectedFilters, newFilter);
        });

        resetTotalValues();
        fetchNewFilters(localSelectedFilters, query, page);
    };

    const deselectAFilter = (newFilter: FilterInContextType, query = queryString, page = 1) => {
        const newFilters = removeFilterFromArray([...selectedFilters], newFilter);
        resetTotalValues();
        fetchNewFilters(newFilters, query, page);
    };

    const deselectMultipleFilters = (newFilters: FilterInContextType[], query = queryString, page = 1) => {
        let localSelectedFilters = [...selectedFilters];
        newFilters.forEach((newFilter) => {
            localSelectedFilters = removeFilterFromArray(localSelectedFilters, newFilter);
        });

        resetTotalValues();
        fetchNewFilters(localSelectedFilters, query, page);
    };

    // ---- Replace all filters by the newFilters ----
    const updateFilters = (newFilters: FilterInContextType[]) => {
        resetTotalValues();
        fetchNewFilters(newFilters, queryString);
    };

    const updatePrefilters = (newFilters: FilterInContextType[]) => {
        if (!currentClient) {
            return;
        }

        // ---- group_garment value for API call of crosswarps ----
        const groupGarment = matchPath('/crosswarp', pathname) && !isCrosswarpView ? 1 : undefined;

        // ---- Update local state ----
        setPrefilters(newFilters);

        // ---- Update URL and Store ----
        updatePrefilterUrlParams(newFilters);
        dispatch(setPageFiltersPrefilters({ key: pathname, newPrefilters: newFilters }));

        // ---- Build new full filters ----
        const filters = getSelectedFilters([...selectedFilters, ...newFilters]);

        // ---- API Call with all filters ----
        dataTrigger(
            {
                clientId: currentClient.id,
                experienceId: selectedExperience?.id,
                filters: { ...defaultFilters, ...filters },
                group_garment: groupGarment,
                page: 1,
                query: queryString,
            },
        ).unwrap().then((data) => {
            setCurrentPage(1);
            setCurrentTotalProduct(data.total_dedup_count || data.total_count);
            setFullProducts(data.items);
            setLocalData(data);
        });

        // ---- API Call WITHOUT Prefilters to update counter facets (needs separate because we need the only facets param) ----
        if (newFilters.length === 0) {
            dataTrigger(
                {
                    clientId: currentClient.id,
                    experienceId: selectedExperience?.id,
                    filters: { ...defaultFilters, ...filters },
                    group_garment: groupGarment,
                    only_facets: 1,
                    page: 1,
                    query: queryString,
                },
            ).unwrap().then((data) => {
                setPrefiltersFacets(data.facets);
            });
        }
    };

    const deselectAll = () => {
        resetTotalValues();
        fetchNewFilters([], queryString, undefined);
    };

    // Called when we scroll the product grid and handle infinite scroll
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80 || !fullProducts) {
            return;
        }

        handlePaginationScroll(
            isLoading,
            currentPage,
            () => fetchNewFilters(selectedFilters, queryString, currentPage + 1),
            localData,
            fullProducts.length,
        );
    };

    const handleSearch = (input: string) => {
        if (timeoutSearchId) {
            clearTimeout(timeoutSearchId);
        }
        timeoutSearchId = setTimeout(() => {
            setQueryString(input);
            fetchNewFilters(selectedFilters, input, undefined);
        }, 300);
    };

    // ---- Init request according to store and url filters ----
    useEffect(() => {
        // ---- Filters from Url and store ----
        const filtersFromUrl = getFiltersFromUrlParams(search);
        const filtersFromStore = storePageFilters?.filters;
        const prefiltersFromUrl = getPrefiltersFromUrlParams(search);
        const prefiltersFromStore = storePageFilters?.prefilters;

        // ---- Exist in URL vars ----
        const hasFiltersInUrl = (filtersFromUrl && filtersFromUrl.length > 0);
        const hasPrefiltersInUrl = (prefiltersFromUrl && prefiltersFromUrl.length > 0);

        // ---- Update store Prefilters if it doesn't exists and we have some in the URL ----
        if (hasPrefiltersInUrl && !prefiltersFromStore) {
            dispatch(setPageFiltersPrefilters({ key: pathname, newPrefilters: prefiltersFromUrl }));
        }

        if (!hasPrefiltersInUrl && prefiltersFromStore) {
            updatePrefilterUrlParams(prefiltersFromStore);
        }

        // ---- Init with Filters from URL ----
        if ((hasFiltersInUrl || hasPrefiltersInUrl) && !storePageFilters) {
            fetchNewFilters(
                filtersFromUrl,
                undefined,
                undefined,
                prefiltersFromUrl,
            );

            return undefined;
        }

        // ---- Sync URL params and init with store filters ----
        if (!hasFiltersInUrl && !hasPrefiltersInUrl && (filtersFromStore || prefiltersFromStore)) {
            // ---- Get new values with correct filter and prefilter ----
            fetchNewFilters(
                filtersFromStore || [],
                undefined,
                undefined,
                prefiltersFromUrl && prefiltersFromUrl.length > 0 ? prefiltersFromUrl : prefiltersFromStore,
            );

            return undefined;
        }

        // ---- Init without filter (or existing filters if URL and store are synced) if there is no request pending and no localData ----
        if (!localData && !isLoading) {
            fetchNewFilters(filtersFromStore || filtersFromUrl, undefined, undefined, prefiltersFromStore || prefiltersFromUrl);
        }

        return undefined;
    }, []);

    // ---- Reserved query -----
    const [getReserved] = useGetReservationMutation();
    useEffect(() => {
        if (noReservationQuery) {
            return undefined;
        }

        const reservedCall = (reservedResp: { data: Reservation[] }) => {
            const castedResp = reservedResp;
            if (castedResp && castedResp.data) {
                dispatch(addReservation(castedResp.data));
            }
        };
        const interval = window.setInterval(() => {
            getReserved().then((reservedResp) => {
                reservedCall(reservedResp as { data: Reservation[] });
            });
        }, 10000);

        getReserved().then((reservedResp) => {
            reservedCall(reservedResp as { data: Reservation[] });
        });

        return () => {
            dispatch(resetReservation());
            clearInterval(interval);
        };
    }, [noReservationQuery]);

    // ---- Set facets without filters for total count ----
    useEffect(() => {
        if (!currentClient || !SubHeaderContent) {
            return undefined;
        }

        // ---- group_garment value for API call of crosswarps ----
        const groupGarment = matchPath('/crosswarp', pathname) && !isCrosswarpView ? 1 : undefined;

        dataTrigger(
            {
                clientId: currentClient.id,
                experienceId: selectedExperience?.id,
                filters: defaultFilters,
                group_garment: groupGarment,
                only_facets: 1,
                page: 1,
                query: '',
            },
        ).unwrap().then((data) => {
            setInitFacets(data.facets);
        });

        return undefined;
    }, [currentClient, defaultFilters, SubHeaderContent]);

    return (
        <VStack boxSize="full">
            <GridSubHeader onSliderChange={setNumerProductPerRow} searchResultsTotal={currentTotalProduct} title={title}>
                <HStack justifyContent={'space-between'} w="100%">
                    {
                        SubHeaderContent
                            ? <FiltersContext.Provider
                                value={{
                                    deselectAFilter,
                                    deselectAll,
                                    deselectMultipleFilters,
                                    filters: selectedFilters,
                                    prefilters,
                                    selectAFilter,
                                    selectMultipleFilters,
                                    updateFilters,
                                    updatePrefilters,
                                }}
                            >
                                <SubHeaderContent facets={prefiltersFacets} initFacets={initFacets} />
                            </FiltersContext.Provider>
                            : <>
                                <div>
                                    {isLoading && <Spinner />}
                                </div>
                                {/* <VStack alignItems={'flex-end'}>
                                    <div>
                                        {t('grid.selected')}{ProductContext?.checkAll ? t('grid.all') : ProductContext?.selectedProducts.length}
                                    </div>
                                    {
                                        ProductContext
                                            && <HStack spacing={8}>
                                                <Button onClick={() => ProductContext.changeCheckAll(true)} variant='link'>
                                                    {t('grid.select_all')}
                                                </Button>
                                                <Button onClick={() => ProductContext.changeCheckAll(false)} variant='link'>
                                                    {t('grid.reset')}
                                                </Button>
                                            </HStack>
                                    }
                                </VStack> */}
                            </>
                    }

                </HStack>

            </GridSubHeader>
            <HStack boxSize="full" overflow="hidden" pl={10} spacing={10}>
                {
                    fullProducts && localData
                    && (
                        <>
                            <FiltersContext.Provider
                                value={{
                                    deselectAFilter,
                                    deselectAll,
                                    deselectMultipleFilters,
                                    filters: selectedFilters,
                                    selectAFilter,
                                    selectMultipleFilters,
                                    updateFilters,
                                }}
                            >
                                <FiltersPanel
                                    currentTotalProduct={currentTotalProduct}
                                    filters={localData.facets}
                                    isLoading={false}
                                    onSearch={handleSearch}
                                    type={type}
                                />
                            </FiltersContext.Provider>
                            <VStack boxSize="full">
                                {
                                    fullProducts.length < 1
                                        ? <Center boxSize="full">{t('grid.no_data')}</Center>
                                        : <FlexWarpComponent
                                            data={fullProducts}
                                            imagesRatio={imagesRatio}
                                            numberProductPerRow={numberProductPerRow}
                                            onScroll={handleScroll}
                                        />
                                }
                            </VStack>
                        </>

                    )
                }
            </HStack>

        </VStack>
    );
}
