// LOCALES
export const AUTH_LOCALES = 'auth';
export const COMMON_LOCALES = 'common';
export const ERROR_LOCALES = 'errors';
export const HOME_LOCALES = 'home';
export const LAYOUT_LOCALES = 'layout';
export const PROFILE_LOCALES = 'profile';
export const GARMENTS_LOCALES = 'garments';
export const PRODUCTS_LOCALES = 'products';
export const PREPROCESSING_LOCALES = 'preprocessing';
export const WARP_LOCALES = 'warp';
export const CROSSWARP_LOCALES = 'crosswarp';
export const WARP_EDIT_LOCALES = 'warpEdit';
export const CHECKFINAL_LOCALES = 'checkfinal';

export const LOCALES = [
    AUTH_LOCALES,
    COMMON_LOCALES,
    ERROR_LOCALES,
    HOME_LOCALES,
    LAYOUT_LOCALES,
    PROFILE_LOCALES,
    GARMENTS_LOCALES,
    PRODUCTS_LOCALES,
    CROSSWARP_LOCALES,
    WARP_EDIT_LOCALES,
    CHECKFINAL_LOCALES,
];

// FILTERS
export enum FILTERS {
    MULTISELECT = 'multi-select',
    RANGESELECT = 'range-select',
}

// PREFILTERS
export enum PREFILTERS {
    TODETAIL = 'TO_DETAIL',
    TOCLIPPING = 'TO_CLIPPING',
    TOFOREGROUND = 'TO_FOREGROUND',
    TOCUT = 'TO_CUT',
    TOQUALITY = 'TO_QUALITY',
    HASFEEDBACK = 'HAS_FEEDBACK',
    TOWARP = 'TO_WARP',
    WARPQUALITY = 'WARP_QUALITY',
    TODO = 'TO_DO',
    TOINTQC = 'TO_INTERNAL_QUALITY',
    TOEXTQC = 'TO_EXTERNAL_QUALITY',
    TOHDQC = 'TO_HD_QUALITY',
    MISSINGOVERRIDE= 'MISSING_OVERRIDE',
    MISSINGSIZING= 'MISSING_SIZING',
    MISSINGALLOWED = 'MISSING_ALLOWED',
    READY = 'READY',
    P1 = 'CRITICAL',
    P2 = 'IMPROVE',
    P7 = 'SMART',
    P8 = 'EXTERNAL',
    P9 = 'TECHNICAL',
    INTERNAL_PENDING = 'INTERNAL_PENDING',
    INTERNAL_FEEDBACK = 'INTERNAL_FEEDBACK',
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
    FEEDBACK = 'FEEDBACK',
}
export const GARMENT_STATUS_KEY = 'garment_status';
export const GARMENT_FEEDBACK_PRIORITY = 'garment_feedback_priority';
export const WARP_STATUS_KEY = 'warp_status';
export const WARP_FEEDBACK_PRIORITY = 'warp_feedback_priority';
export const CROSSWARP_STATUS_KEY = 'crosswarp_status';
export const CHECKFINAL_STATUS_KEY = 'garment_check';
export const CROSSWARP_FEEDBACK_PRIORITY = 'crosswarp_feedback_priority';
export const TODO_VALUES = ['TO_DETAIL', 'TO_CLIPPING', 'TO_FOREGROUND', 'TO_CUT'];

// DEFAULT FILTERS
export const DEFAULT_PREPROCESSING_FILTERS = {
    [GARMENT_STATUS_KEY]: [
        PREFILTERS.TOQUALITY,
        PREFILTERS.HASFEEDBACK,
        PREFILTERS.TOWARP,
        PREFILTERS.WARPQUALITY,
        PREFILTERS.READY,
        ...TODO_VALUES,
    ],
};
export const DEFAULT_REFWARP_FILTERS = {
    [WARP_STATUS_KEY]: [
        PREFILTERS.MISSINGALLOWED,
        PREFILTERS.TOQUALITY,
        PREFILTERS.HASFEEDBACK,
        PREFILTERS.TODO,
        PREFILTERS.TOINTQC,
        PREFILTERS.TOEXTQC,
        PREFILTERS.TOHDQC,
        PREFILTERS.READY,
    ],
};
export const DEFAULT_CROSSWARP_FILTERS = {
    [WARP_STATUS_KEY]: [
        PREFILTERS.READY,
    ],
};

// Warp Edit Steps
export enum WARPEDIT_STEPS {
    EDIT = 'edit',
    SMART_COMPARISON = 'smart_comparison',
    SIZING = 'sizing',
    FINAL_CHECK = 'final_check',
    FINISH = 'finish'
}

export const DEFAULT_WARPEDIT_STEPS: string[] = [
    WARPEDIT_STEPS.EDIT,
    WARPEDIT_STEPS.SMART_COMPARISON,
    WARPEDIT_STEPS.SIZING,
    WARPEDIT_STEPS.FINAL_CHECK,
    WARPEDIT_STEPS.FINISH,
];

// QC STEPS
export enum QCSTEPS {
    EXTERNAL_CHECK = 'client_validation',
    HD_CHECK = 'hd_check',
    FIT_CONSISTENCY = 'fit_consistency',
    SMART = 'smart',
    COVER_AND_TEXTURE = 'cover_and_texture',
    MORE_MODELS = 'more_models',
    OUTFIT_PREVIEW = 'outfit_preview',
    CLIPPING= 'clipping',
    FOREGROUND = 'foreground',
    CUT = 'cut',
    MASK = 'mask',
    MESH = 'mesh',
    SIZING = 'sizing',
    DETAIL = 'detail',
    FEEDBACKS = 'feedbacks',
    TRANSPARENCY = 'transparency'
}

export const PREPROCESSING_STEPS: string[] = [QCSTEPS.DETAIL, QCSTEPS.CLIPPING, QCSTEPS.FOREGROUND, QCSTEPS.CUT, QCSTEPS.MASK, QCSTEPS.MESH];
export const REFWARP_STEPS: string[] = [
    QCSTEPS.FEEDBACKS,
    QCSTEPS.FIT_CONSISTENCY,
    QCSTEPS.SMART,
    QCSTEPS.COVER_AND_TEXTURE,
    QCSTEPS.MORE_MODELS,
    QCSTEPS.OUTFIT_PREVIEW,
    QCSTEPS.TRANSPARENCY,
    QCSTEPS.EXTERNAL_CHECK,
    QCSTEPS.HD_CHECK,
];
export const CROSSWARP_STEPS: string[] = [
    QCSTEPS.FEEDBACKS,
    QCSTEPS.FIT_CONSISTENCY,
    QCSTEPS.SIZING,
    QCSTEPS.SMART,
    QCSTEPS.COVER_AND_TEXTURE,
    QCSTEPS.OUTFIT_PREVIEW,
    QCSTEPS.HD_CHECK,
];

// QC STATUS UNAUTHORIZED
export const UNAUTHORIZED_PPQC_STATUS = TODO_VALUES;
export const UNAUTHORIZED_RWQC_STATUS: string[] = [PREFILTERS.TODO, 'PENDING_QUALITY', 'PENDING_PRIMARY'];

// ----- Api fallback -----
export const FALLBACK_API_URL = 'https://app.veesual.ai/api/';

// ----- Action Constant -----
export const LOGOUT_ACTION = { type: 'logout' };

// ----- Storage Constant -----
export const AUTH_TOKEN_KEY = 'authToken';
export const AUTH_REFRESH_TOKEN_KEY = 'authRefreshToken';

// ----- Header Constant -----
export const MAIN_HEADER_HEIGHT = 64;
export const SUB_HEADER_HEIGHT = 100;

// ---- ROLE Constant ----
export const BACKEND = 'ROLE_BACKEND';
export const ADMIN = 'ROLE_ADMIN';
export const SUPERADMIN = 'ROLE_SUPER_ADMIN';

export const SUPERADMIN_PERMISSION = [
    'dashboard',
    'input',
    'preprocessing',
    'ref_warp.read',
    'ref_warp.write',
    'ref_warp.warp',
    'ref_warp.internal',
    'ref_warp.admin',
    'crosswarp.read',
    'crosswarp.write',
    'crosswarp.warp',
    'crosswarp.internal',
    'warp.nosmart',
    'outfit',
    'feedback',
    'external_validation',
    'check.internal',
    'old_routes',
];

export const ADMIN_PERMISSION = [
    'dashboard',
    'input',
    'preprocessing',
    'ref_warp.read',
    'ref_warp.write',
    'ref_warp.warp',
    'ref_warp.internal',
    'ref_warp.admin',
    'crosswarp.read',
    'crosswarp.write',
    'crosswarp.warp',
    'crosswarp.internal',
    'warp.nosmart',
    'outfit',
    'feedback',
    'external_validation',
    'check.internal',
    'old_routes',
];

export const BACKEND_WARP_PERMISSION = [
    'ref_warp.read',
    'ref_warp.warp',
    'crosswarp.read',
    'crosswarp.warp',
    'feedback',
];

export const BACKEND_QUALITY_PERMISSION = [
    'ref_warp.read',
    'ref_warp.write',
    'crosswarp.read',
    'crosswarp.write',
    'feedback',
];

export const BACKEND_RWINTQC_PERMISSION = [
    'ref_warp.internal',
];

// ---- Image Slide local storage keys ----
export const DEFAULT_IMAGESIZE_KEY = 'imagePerRow';
export const CROSSWARP_IMAGESIZE_KEY = 'crosswarp_check_image_size';

// ---- Feedback Status ----
export enum FEEDBACK_STATUS {
    CANCELED = 'CANCELED',
    DONE = 'DONE',
    PENDING = 'PENDING',
}
