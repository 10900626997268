import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FilterInContextType } from '../../../components/filters/filtersTypes';
import { ClientConfig, ClientResponse, Experience, Filters, Reservation, UserResponse } from '../../../types/api-types';
import { localTokenExist } from '../../../utils/auth-helpers';
import type { RootState } from '../store';

interface BlockNavModalObject {newPath?: string, visible: boolean, prevPath?: string}
interface PathBlockStateObject {[key: string]: {block: boolean, message?: string}}

export interface SessionSlice {
    authenticated: boolean,
    client: ClientResponse | null,
    isClientInitialized: boolean, // This is used to know if we finished fetching the clients eventhough there is none
    config: ClientConfig | null,
    experience: Experience | null,
    user: UserResponse | null,
    isCrosswarpView: boolean,
    pageFilters: {
        [key: string]: {
            filters?: FilterInContextType[],
            prefilters?: FilterInContextType[],
        } | undefined
    },
    errorImages: string[],
    reservations: Reservation[],
    blockNavModal: BlockNavModalObject,
    pathBlockState: PathBlockStateObject
    }

const initialState: SessionSlice = {
    authenticated: localTokenExist(),
    blockNavModal: { visible: false },
    client: null,
    config: null,
    errorImages: [],
    experience: null,
    isClientInitialized: false,
    isCrosswarpView: true,
    pageFilters: {},
    pathBlockState: {},
    reservations: [],
    user: null,
};

// Slice storing the tokens used in the API calls
export const sessionSlice = createSlice({
    initialState,
    name: 'session',
    reducers: {
        addErrorImage: (state: SessionSlice, action: PayloadAction<string>) => {
            // ---- Check if we already have this img in the list ----
            if (!state.errorImages.includes(action.payload)) {
                state.errorImages.push(action.payload);
            }
        },
        addReservation: (state: SessionSlice, action: PayloadAction<Reservation[]>) => {
            state.reservations = action.payload;
        },
        resetErrorImage: (state: SessionSlice) => {
            state.errorImages = [];
        },
        resetPageFilters: (state: SessionSlice, action: PayloadAction<string>) => {
            state.pageFilters[action.payload] = undefined;
        },
        resetReservation: (state: SessionSlice) => {
            state.reservations = [];
        },
        setAuthenticated: (state: SessionSlice, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
        },
        setBlockNavModal: (state: SessionSlice, action: PayloadAction<BlockNavModalObject>) => {
            state.blockNavModal = action.payload;
        },
        setClient: (state: SessionSlice, action: PayloadAction<ClientResponse | null>) => {
            state.client = action.payload;
            state.isClientInitialized = true;
        },
        setConfig: (state: SessionSlice, action: PayloadAction<ClientConfig | null>) => {
            state.config = action.payload;
        },
        setExperience: (state: SessionSlice, action: PayloadAction<Experience | null>) => {
            state.experience = action.payload;
        },
        setIsCrosswarpView: (state: SessionSlice, action: PayloadAction<boolean>) => {
            state.isCrosswarpView = action.payload;
        },
        setPageFiltersFilters: (state: SessionSlice, action: PayloadAction<{key: string, newActiveFilters: FilterInContextType[] | undefined}>) => {
            state.pageFilters[action.payload.key] = {
                filters: action.payload.newActiveFilters,
                prefilters: state.pageFilters[action.payload.key]?.prefilters,
            };
        },
        setPageFiltersPrefilters: (
            state: SessionSlice, action: PayloadAction<{key: string, newPrefilters: FilterInContextType[] | undefined}>,
        ) => {
            state.pageFilters[action.payload.key] = {
                filters: state.pageFilters[action.payload.key]?.filters,
                prefilters: action.payload.newPrefilters,
            };
        },
        setPathBlockState: (state: SessionSlice, action: PayloadAction<{key: string, newValue: {block: boolean, message?: string}}>) => {
            state.pathBlockState[action.payload.key] = action.payload.newValue;
        },
        setUser: (state: SessionSlice, action: PayloadAction<UserResponse>) => {
            state.user = action.payload;
        },
    },
});

// ----- Actions -----
export const {
    setAuthenticated, setClient, setConfig, setExperience, setUser, setPageFiltersFilters, setPageFiltersPrefilters,
    setIsCrosswarpView, addErrorImage, resetErrorImage, addReservation, resetReservation, resetPageFilters,
    setBlockNavModal, setPathBlockState,
} = sessionSlice.actions;

// ---- Selector Utils ----
const convertFiltersInContext = (filters: FilterInContextType[]) => {
    const filterObject: Filters = {};
    filters.forEach((filter) => {
        if (filterObject[filter.filterKey]) {
            if (Array.isArray(filterObject[filter.filterKey])) {
                filterObject[filter.filterKey] = [...filterObject[filter.filterKey] as string[], filter.filterValue];
            } else {
                filterObject[filter.filterKey] = [filterObject[filter.filterKey] as string, filter.filterValue];
            }
        } else {
            filterObject[filter.filterKey] = filter.filterValue;
        }
    });

    return filterObject;
};

// ----- Selector -----
export const isAuthenticated = (state: RootState) => state.session.authenticated;
export const hasClient = (state: RootState) => state.session.client !== null;
export const getClient = (state: RootState) => state.session.client;
export const isClientInitialized = (state: RootState) => state.session.isClientInitialized;
export const getConfig = (state: RootState) => state.session.config;
export const getExperience = (state: RootState) => state.session.experience;
export const getUser = (state: RootState) => state.session.user;
export const getPageFilters = (state: RootState, key: string) => state.session.pageFilters[key];
export const getConvertedPageFiltersFilters = (state: RootState, key: string) => {
    const pageFilters = state.session.pageFilters[key];
    if (!pageFilters || !pageFilters.filters) {
        return null;
    }

    return convertFiltersInContext(pageFilters.filters);
};
export const getConvertedPageFiltersPrefilters = (state: RootState, key: string) => {
    const pageFilters = state.session.pageFilters[key];
    if (!pageFilters || !pageFilters.prefilters) {
        return null;
    }

    return convertFiltersInContext(pageFilters.prefilters);
};
export const getIsCrosswarpView = (state: RootState) => state.session.isCrosswarpView;
export const getErrorImages = (state: RootState) => state.session.errorImages;
export const getPathBlock = (state: RootState, key: string) => state.session.pathBlockState[key];

export default sessionSlice.reducer;
