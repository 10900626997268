import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getPathBlock, setBlockNavModal, setPathBlockState } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { COMMON_LOCALES } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';

export default function BlockNavModal() {
    const dispatch = useDispatch();
    const blockNavModal = useAppSelector((state) => state.session.blockNavModal);
    const currentPathBlockState = useAppSelector((state) => getPathBlock(state, blockNavModal?.prevPath || ''));
    const navigate = useCustomNavigate();
    const { t } = useTranslation(COMMON_LOCALES);

    const cancelRef = React.useRef(null);

    const handleOnValidate = () => {
        if (blockNavModal.prevPath && blockNavModal.newPath) {
            dispatch(setPathBlockState({ key: blockNavModal.prevPath, newValue: { block: false, message: currentPathBlockState.message } }));
        }
    };

    const handleOnClose = () => {
        dispatch(setBlockNavModal({ visible: false }));
    };

    // ---- Need this use effect because we need to navigate after the blockState is changed so it doesn't get blocked again ----
    useEffect(() => {
        if (!currentPathBlockState?.block && blockNavModal.newPath) {
            navigate(blockNavModal.newPath);
            dispatch(setBlockNavModal({ visible: false }));
        }
    }, [currentPathBlockState]);

    return (
        <AlertDialog
            isOpen={blockNavModal.visible}
            leastDestructiveRef={cancelRef}
            onClose={handleOnClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {t('block_nav.title')}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {currentPathBlockState?.message || t('block_nav.description')}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={handleOnClose} ref={cancelRef} variant='ghost'>
                            {t('cancel')}
                        </Button>
                        <Button ml={3} onClick={handleOnValidate}>
                            {t('confirm')}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}
