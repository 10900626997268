import 'react-datepicker/dist/react-datepicker.css';

import { HStack, Icon, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { FaRegCalendar } from 'react-icons/fa';

interface DashboardDateInputProps {
    fullDate?: string,
    onChange?(newFullDate?: string): void,
    label?: string
}

export const formatDateToInput = (dateToFormat: Date): string => {
    const year = dateToFormat.getFullYear();
    const month = String(dateToFormat.getMonth() + 1).padStart(2, '0');
    const day = String(dateToFormat.getDate()).padStart(2, '0');

    const time = `${String(dateToFormat.getHours()).padStart(2, '0')}:${String(dateToFormat.getMinutes()).padStart(2, '0')}`;

    return `${year}-${month}-${day}T${time}`;
};

export default function DashboardDateInput(props: DashboardDateInputProps) {
    const { fullDate, onChange, label } = props;

    const fullDateObj = useMemo(() => {
        if (!fullDate) {
            return null;
        }

        const newDateObj = new Date(fullDate);

        return new Date(newDateObj.getTime() + newDateObj.getTimezoneOffset() * 60000);
    }, [fullDate]);

    // ---- Min date is the first date we got history in the DB ----
    const minValue = useMemo(() => new Date('2024-10-05'), []);
    const maxValue = useMemo(() => new Date(Date.now()), []);

    const handleDateChange = (newDate: Date | null) => {
        if (!onChange) {
            return;
        }

        if (!newDate) {
            onChange();

            return;
        }

        onChange(formatDateToInput(newDate));
    };

    return (
        <HStack zIndex={4}>
            {label && <Text>{label}</Text>}
            <DatePicker
                className='datepicker-input'
                clearButtonClassName='datepicker-button-clear'
                dateFormat="dd/MM/yyyy  HH:mm"
                icon={<Icon as={FaRegCalendar} />}
                isClearable
                maxDate={maxValue}
                minDate={minValue}
                onChange={handleDateChange}
                placeholderText='--/--/--  --:--'
                selected={fullDateObj}
                showIcon
                showTimeSelect
                timeIntervals={60}
                toggleCalendarOnIconClick
            />
        </HStack>
    );
}
