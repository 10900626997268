import qs from 'qs';

import { FilterInContextType } from '../components/filters/filtersTypes';

export const getObjectUrl = (urlParams: string) => qs.parse(urlParams);

const removeQuestionMark = (urlParams: string) => {
    let cloneUrlParams = urlParams;
    if (cloneUrlParams.charAt(0) === '?') {
        cloneUrlParams = urlParams.slice(1);
    }

    return cloneUrlParams;
};

export const addFilterToKeyUrlParams = (urlParams: string, filters: FilterInContextType[], key: string) => {
    const parsedUrl = getObjectUrl(removeQuestionMark(urlParams));
    const newFilterObject = {} as {[key:string]: string | string[]};
    filters.forEach((localFilter) => {
        const urlValue = newFilterObject[localFilter.filterKey];
        let newValue: string | string[];
        if (urlValue) {
            if (Array.isArray(urlValue)) {
                newValue = [...urlValue, localFilter.filterValue];
            } else {
                newValue = [urlValue as string, localFilter.filterValue];
            }
        } else {
            newValue = localFilter.filterValue;
        }
        newFilterObject[localFilter.filterKey] = newValue;
    });

    parsedUrl[key] = newFilterObject;

    return qs.stringify(parsedUrl);
};

export const removeKeysFromUrlParams = (urlParams: string, keys: string[]) => {
    const parsedUrl = getObjectUrl(removeQuestionMark(urlParams));
    keys.forEach((key) => {
        delete parsedUrl[key];
    });

    return qs.stringify(parsedUrl);
};

const buildFilterInContextArray = (filter: string | string[] | qs.ParsedQs | qs.ParsedQs[]) => {
    const filterInContextArray = [] as FilterInContextType[];
    const typedUrlFilter = filter as {[key:string]: string | string[]};

    Object.keys(typedUrlFilter).forEach((localKey) => {
        if (Array.isArray(typedUrlFilter[localKey])) {
            (typedUrlFilter[localKey] as string[]).forEach((filterValue) => {
                filterInContextArray.push({ filterKey: localKey, filterValue });
            });
        } else {
            filterInContextArray.push({ filterKey: localKey, filterValue: typedUrlFilter[localKey] as string });
        }
    });

    return filterInContextArray;
};

export const getPrefiltersFromUrlParams = (urlParams: string) => {
    const parsedUrl = getObjectUrl(removeQuestionMark(urlParams));
    let prefilterInContextArray = [] as FilterInContextType[];

    if (parsedUrl.prefilter) {
        prefilterInContextArray = buildFilterInContextArray(parsedUrl.prefilter);
    }

    return prefilterInContextArray;
};

export const getFiltersFromUrlParams = (urlParams: string) => {
    const parsedUrl = getObjectUrl(removeQuestionMark(urlParams));
    let filterInContextArray = [] as FilterInContextType[];

    if (parsedUrl.filter) {
        filterInContextArray = buildFilterInContextArray(parsedUrl.filter);
    }

    return filterInContextArray;
};

export const getCleanSearch = (search: string) => {
    const parsedUrl = getObjectUrl(search);

    return qs.stringify({ clientId: parsedUrl.clientId || parsedUrl['?clientId'], mode: parsedUrl.mode || parsedUrl['?mode'] });
};

export const getGarmentDetailUrl = (garmentId: string) => `https://app.veesual.ai/admin/garment/${garmentId}/detail`;
export const getWarpDetailUrl = (warpId: string) => `https://app.veesual.ai/admin/warp/${warpId}/detail`;
export const getModelDetailUrl = (modelId: string) => `https://app.veesual.ai/admin/model/${modelId}/detail`;
export const getNewWarpEditUrl = (garmentId: string) => `https://app.veesual.ai/admin/garment/${garmentId}/warp`;
